import { Pagination, Table } from 'antd'
import React, { useCallback, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import StyledWithdrawlHistory from './WithdrawalHistory.styles'
import { useLazyGetHistoryQuery } from '../../../../Services/Users'

function WithdrawlHistory() {
  const { id } = useParams()
  const [page, setPage] = useState(1)
  const [pagiData, setPagiData] = useState()
  const [historyData, setHistoryData] = useState()
  const [history] = useLazyGetHistoryQuery()

  const handleChange = useCallback(
    async (e = 1) => {
      const data1 = {
        id,
        page: e,
      }
      try {
        const response = await history(data1).unwrap()
        setPagiData(response)
        const arr = response?.investment?.data.map((item) => ({
          amount: item?.amount,
          status: item?.status ?? 'Investment',
          from: item?.projectId?.name,
          createdAt: dayjs(item?.createdAt).format('DD-MM-YYYY'),
        }))

        setHistoryData(arr)
        setPage(Number(response?.investment?.pagination?.page))
      } catch (error) {
        console.error(error)
      }
    },
    [history, id]
  )

  const columns = [
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'From',
      dataIndex: 'from',
      key: 'from',
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
  ]

  useEffect(() => {
    if (page === 1) {
      handleChange(1)
    }
  }, [handleChange])

  return (
    <StyledWithdrawlHistory>
      <Table
        style={{ overflow: 'auto' }}
        dataSource={historyData}
        columns={columns}
        pagination={false}
      />
      <div className="pagination">
        <Pagination
          total={pagiData?.investment?.pagination?.total}
          showSizeChanger={false}
          defaultPageSize="4"
          current={page}
          onChange={(e) => {
            handleChange(e)
          }}
        />
      </div>
    </StyledWithdrawlHistory>
  )
}

export default WithdrawlHistory
