import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import './index.css'
import App from './App'
import 'antd/dist/reset.css'
import Store from './Store'
import ContextAppProvider from './Context/ContextApp'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <Provider store={Store}>
      <BrowserRouter>
        <ContextAppProvider>
          <App />
        </ContextAppProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
)
