import styled from 'styled-components'

const StyledWithdrawlHistory = styled.div`
  > .ant-pagination
    > .ant-pagination-prev
    > .ant-pagination-item-link
    > .anticon
    > svg {
    fill: #7000ff;
  }
  .pagination
    > .ant-pagination
    > .ant-pagination-next
    > .ant-pagination-item-link
    > .anticon {
    font-size: 16px;
  }
  .pagination
    > .ant-pagination
    > .ant-pagination-prev
    > .ant-pagination-item-link
    > .anticon {
    font-size: 16px;
  }
  .pagination
    > .ant-pagination
    > .ant-pagination-next
    > .ant-pagination-item-link
    > .anticon
    > svg {
    fill: #7000ff;
  }
  .pagination
    > .ant-pagination
    > .ant-pagination-disabled
    > .ant-pagination-item-link
    > .anticon
    > svg {
    ${(props) => props.theme.colors.grey};
  }
  .pagination > .ant-pagination > .ant-pagination-item-active {
    height: 18px !important;
    width: 70px !important;
    background: ${(props) => props.theme.colors.blue.light} !important;
    border-radius: 1000px;
    height: 15px;
    width: 52px;
    border-color: transparent;
    margin-top: 0px;
    padding-top: 0px;
  }

  .pagination > .ant-pagination > .ant-pagination-item {
    height: 18px;
    border-radius: 1000px;
    height: 15px;
    width: 52px;
    background: rgba(255, 255, 255, 0.2);
    cursor: pointer;
    a {
      display: none;
    }
  }

  .ant-table-thead > tr > th {
    background: ${(props) => props.theme.colors.blue.dark};
    color: ${(props) => props.theme.colors.white};
    border: none;
    border-radius: 0 !important;
    font-weight: 400 !important;
    font-size: 14px;
  }
  .ant-table-thead > tr > th:before {
    display: none;
  }
  .ant-table-tbody > tr > td {
    background: ${(props) => props.theme.colors.blue.dark};
    color: ${(props) => props.theme.colors.white};
    border-bottom: none;
    border-top: 1px solid ${(props) => props.theme.colors.white};
    border-left: none;
    font-weight: 600 !important;
    font-size: 16px;
  }
  .ant-table-tbody > tr:hover {
    td {
      background: ${(props) => props.theme.colors.blue.dark} !important;
    }
  }
`

export default StyledWithdrawlHistory
