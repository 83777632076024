import styled from 'styled-components'

const StyledDisconnect = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  .name {
    text-align: left;
    display: flex;
    flex-direction: column;
    .disconnect-btn {
      text-align: left;
      height: 20px;
      border: none;
      background: transparent;
      border-radius: 8px;
      color: #8a8aa0;
      padding-left: 0;
      font-family: 'Gilroy';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
      text-transform: capitalize;
      color: ${(props) => props.theme.colors.blue.light};
      padding-top: 0;
      span {
        font-family: 'Gilroy';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        margin: 0;
        line-height: 24px;

        text-transform: capitalize;
      }
    }
    p {
      font-family: 'Gilroy';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      margin: 0;
      line-height: 24px;
      text-transform: capitalize;
      cursor: pointer;
      color: ${(props) => props.theme.colors.white};
    }
  }

  .ant-btn > div {
    display: none;
  }
  .profile {
    cursor: pointer;
  }
`

export default StyledDisconnect
