import { Input } from 'antd'
import styled from 'styled-components'
import { deviceQuery } from '../../Constants/MediaSizes'

export const StyledCreatePass = styled.div`
  width: 80%;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding-top: 150px;
  h4 {
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 34px;
    /* or 122% */

    text-align: center;
    text-transform: capitalize;

    /* On Surface */

    color: ${(props) => props.theme.colors.white};
  }
  p {
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    /* identical to box height, or 133% */

    text-align: center;
    letter-spacing: 0.02em;

    color: #79869f;
  }

  .create-form {
    width: 50%;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 70px;
  }
  @media (${deviceQuery.laptopM}) {
    .create-form {
      width: 100%;
    }
  }
  @media (${deviceQuery.laptopM}) {
    .create-form {
      padding: 20px;
    }
  }
`
export const StyledPassword = styled(Input.Password)`
  background: ${(props) => props.theme.colors.blue.dark};
  border-color: #343444;
  color: ${(props) => props.theme.colors.white};
  height: 40px;
  input {
    color: ${(props) => props.theme.colors.white} !important;
    background: ${(props) => props.theme.colors.blue.dark};
  }
  & input::placeholder {
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;

    color: #8a8aa0;
    text-align: center;
  }
  & > .ant-input-suffix > .anticon {
    color: #8a8aa0;
  }
  .ant-form-item-explain-error {
    text-align: left;
  }
`
