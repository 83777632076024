import { useContext } from 'react'
import { ContextApp } from '../Context/ContextApp'

const useContextApp = () => {
  const state = useContext(ContextApp)
  if (!state) {
    throw new Error('ContextApp used outside provider')
  }

  return state
}

export default useContextApp
