export const setLocalStorageItem = (key, value) => {
  // const stringify = JSON.stringify(value)

  localStorage.setItem(key, value)
}

export const setStepLocalStorageItem = (key, value) => {
  const stringify = JSON.stringify(value)

  localStorage.setItem(key, stringify)
}

export const setLocalStorageItems = (arr) => {
  arr.forEach((item) => {
    setLocalStorageItem({ key: item.key, value: item.value })
  })
}

export const removeLocalStorageItem = (key) => localStorage.removeItem(key)

export const removeLocalStorageItems = (arr) => {
  arr.forEach((item) => {
    removeLocalStorageItem({ key: item.key })
  })
}

export const getLocalStorageItem = (key) => {
  const item = localStorage.getItem(key)
  if (item) {
    return item
  }
  return undefined
}

export const getStepLocalStorageItem = (key) => {
  const item = localStorage.getItem(key)
  if (item) {
    return JSON.parse(item)
  }
  return undefined
}

// eslint-disable-next-line max-len
export const getLocalStorageItems = (arr) =>
  arr.map((item) => getLocalStorageItem({ key: item.key }))
