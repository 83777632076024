/* eslint-disable import/named */
import { Form } from 'antd'
import React from 'react'
import { useNavigate } from 'react-router'
import { toast } from 'react-toastify'
import Create from '../../Assets/Create'

// import Navbar from '../../Components/Navbar/Navbar'
import ROUTES from '../../Constants/Routes'
import { useResetPasswordMutation } from '../../Services/PublicApis'

import { StyledButton } from '../ForgotForm/ForgotForm.styles'
import { StyledCreatePass, StyledPassword } from './CreatePass.styles'
import { validatePassword } from '../SignUp/SignUp'

function CreatePass({ token }) {
  const navigate = useNavigate()
  // const location = useLocation()
  const [resetPassword] = useResetPasswordMutation()
  const handleResetPassword = async (values) => {
    try {
      const response = await resetPassword({
        ...values,
        token,
      }).unwrap()
      toast.success(response?.message)
      navigate(ROUTES.PASSWORDCHANGED, { replace: true })
    } catch (err) {
      // console.log(err, 'err')
      toast.error(err?.message || err?.data?.message)
      if (err?.data?.message === 'Session Expired ') {
        navigate(ROUTES.LOGIN)
      }
    }
  }

  return (
    <StyledCreatePass>
      {/* <Navbar /> */}
      <div className="create-form">
        <h4>Create new password</h4>
        <p>Don’t forget to create a strong password !</p>
        <div className="create-img">
          <Create />
        </div>

        <Form
          className="form"
          name="basic"
          style={{
            maxWidth: '100%',
          }}
          initialValues={{
            remember: true,
          }}
          autoComplete="off"
          onFinish={handleResetPassword}
        >
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: 'Please input new password',
              },
              {
                message:
                  'Password must contain at least 8 characters, including one uppercase, one lowercase, one numeric & one special character',
                validator: validatePassword,
              },
            ]}
          >
            <StyledPassword placeholder="New Password" />
          </Form.Item>
          <Form.Item
            dependencies={['password']}
            name="confirmPassword"
            rules={[
              {
                required: true,
                message: 'Please input password',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve()
                  }
                  return Promise.reject(new Error("Password doesn't match"))
                },
              }),
            ]}
          >
            <StyledPassword placeholder="Confirm Password" />
          </Form.Item>
          <StyledButton
            type="primary"
            htmlType="submit"
            className="login-btn"
            size="large"
          >
            Set Password
          </StyledButton>
        </Form>
      </div>
    </StyledCreatePass>
  )
}

export default CreatePass
