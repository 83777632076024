import React from 'react'
import { Form } from 'antd'
import { Option } from 'antd/es/mentions'
import Logo from '../../Assets/Logo'
import ROUTES from '../../Constants/Routes'
import {
  StyledLoginInput,
  StyledNavlink,
  StyledSelect,
  StyledTextArea,
} from '../../GlobalStyles'
import { StyledMessage, StyledButton } from './MessageForm.styles'
import Frame from '../../Assets/Frame'

function MessageForm() {
  return (
    <StyledMessage>
      <div className="nav-apply">
        <div className="nav-title">
          <StyledNavlink to={ROUTES.HOW_IT_WORKS}>How It Works</StyledNavlink>
          <StyledNavlink to={ROUTES.SUBMIT_IDEAS}>Submit Ideas</StyledNavlink>
          <StyledNavlink to={ROUTES.ABOUT_US}>About Us</StyledNavlink>
        </div>
        <div className="nav-logo">
          <StyledNavlink>
            <Logo fill="#7000FF" />
          </StyledNavlink>
        </div>
      </div>
      <div className="main-body">
        <div className="body-img">
          <div className="title-details">
            <Frame />
            <h2 className="title">UI/UX Research</h2>
            <p className="desc">
              Advanced project that gonna help you save your life data and help
              improving habits.
            </p>
          </div>
        </div>
        <div className="body-form">
          <h3 className="title">Drop up a message</h3>
          <p className="title">Drop up a message</p>
          <div className="form-details">
            <Form
              className="message-form"
              name="basic"
              style={{
                maxWidth: '100%',
              }}
              initialValues={{
                remember: true,
              }}
              autoComplete="off"
            >
              <Form.Item
                name="name"
                style={{ width: '100%' }}
                rules={[
                  {
                    required: true,
                    message: 'Please input name',
                  },
                ]}
              >
                <StyledLoginInput placeholder="Please enter name" />
              </Form.Item>

              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: 'Please input email',
                  },
                ]}
              >
                <StyledLoginInput placeholder="Your Email Address" />
              </Form.Item>
              <Form.Item
                name="subject"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <StyledSelect
                  size="large"
                  placeholder="Select Subject   "
                  // onChange={onValueChange}
                  allowClear
                >
                  <Option value="Englishr">English</Option>
                  <Option value="Hindi">Hindi</Option>
                </StyledSelect>
              </Form.Item>
              <Form.Item
                style={{ width: '100%' }}
                rules={[
                  {
                    required: true,
                    message: 'Please enter your address',
                  },
                ]}
              >
                <StyledTextArea placeholder="Message" />
              </Form.Item>

              <StyledButton
                type="primary"
                htmlType="submit"
                className="login-btn"
              >
                Send Message
              </StyledButton>
              <p className="acc-desc">
                or
                <StyledNavlink to={ROUTES.APPLY_FORM}>Apply Now.</StyledNavlink>
              </p>
            </Form>
          </div>
        </div>
      </div>
    </StyledMessage>
  )
}

export default MessageForm
