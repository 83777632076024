/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-unused-expressions */
import { React, useState, useEffect, useRef } from 'react'
import { Button, Divider, Checkbox, Form } from 'antd'
import dayjs from 'dayjs'
import { Country, City } from 'country-state-city'
import { toast } from 'react-toastify'
import { useForm } from 'antd/es/form/Form'
import { useNavigate } from 'react-router'
import StyledSignUp from './SignUp.styles'
// import Navbar from '../../Components/Navbar/Navbar'
import {
  StyledButton,
  StyledLoginInput,
  StyledLoginPassword,
  StyledSelect,
} from '../../GlobalStyles'
import CustomDateSelect from '../../Components/CustomDateSelect'
import {
  useSendOTPEmailMutation,
  useSendOTPMobileMutation,
  useSignupMutation,
  useVerifyEmailMutation,
  useVerifyMobileMutation,
} from '../../Services/PublicApis'
import GoogleLoginn from '../../Components/GoogleLogin'
import VerifyNumModal from '../../Components/VerifyNumModal'
import ROUTES from '../../Constants/Routes'
import { handlePaste } from '../../Utils/Common'
import { ROLE } from '../../Constants/Enums'
import { getLocalStorageItem } from '../../Utils/localStorage'

export const validatePassword = (_, value) => {
  const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@?#$%^&*()_+])[a-zA-Z\d!@?#$%^&*()_+]{8,}$/
  return new Promise((resolve, reject) => {
    if (!passwordRegex.test(value)) {
      reject(
        'Password must contain at least 8 characters, including one uppercase, one lowercase, one numeric & one special character'
      )
    } else {
      resolve()
    }
  })
}

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
}

function SignUp() {
  const restrictedKeys = ['+', '-', 'e', '.']
  const [date, setDate] = useState('')
  const [countries, setCountries] = useState([])
  const [cities, setCities] = useState([])
  const dateRef = useRef()
  const [selectedCountryCode, setSelectedCountryCode] = useState('IN')
  const [currentFn, setCurrenFn] = useState()
  const [phoneNumberToken, setPhoneToken] = useState('')
  const [emailToken, setEmailToken] = useState('')
  const [deadline, setDeadline] = useState(0)

  // const [isVerified, setIsVerified] = useState(0)
  const [signup, { isLoading: signUpLoading }] = useSignupMutation()
  const [sendOTPEmail, { isLoading: sendEmailLoading }] =
    useSendOTPEmailMutation()
  const [sendOTPMobile, { isLoading: sendMobileLoading }] =
    useSendOTPMobileMutation()
  const [verifyEmail, { isLoading: verifyEmailLoading }] =
    useVerifyEmailMutation()
  const [verifyMobile, { isLoading: verifyMobileLoading }] =
    useVerifyMobileMutation()
  const [signUpForm] = useForm()
  const role = getLocalStorageItem('role') ?? ROLE.CLIENT

  const d = new Date()
  const year = d.getFullYear() - 1
  const navigate = useNavigate()
  useEffect(() => {
    setCountries(
      Country.getAllCountries()?.map((item, i) => ({
        key: i,
        label: item?.name,
        value: item?.name,
        isoCode: item?.isoCode,
        phoneCode: item?.phonecode,
      }))
    )
    setCities(
      City.getCitiesOfCountry(selectedCountryCode)?.map((item, i) => ({
        key: i,
        label: item?.name,
        value: item?.name,
      }))
    )
  }, [selectedCountryCode])

  const [opens, setOpens] = useState(false)

  const handleEmailVerification = async (otp, setOtp) => {
    const email = signUpForm.getFieldValue('email').toLowerCase()
    const data = {
      email,
      otp: Number(otp),
    }
    const response = await verifyEmail(data).unwrap()
    setEmailToken(response?.emailToken)
    // setIsVerified((prev) => prev + 1);
    toast.success(response?.message)
    setOtp('')
    setOpens(false)
  }

  const handlePhoneVerification = async (otp, setOtp) => {
    const phoneNumber = Number(signUpForm.getFieldValue('phoneNumber'))
    const countryCode = signUpForm.getFieldValue('countryCode')
    const data = {
      phoneNumber,
      countryCode,
      otp: Number(otp),
    }
    const response = await verifyMobile(data).unwrap()
    setPhoneToken(response?.phoneNumberToken)
    // setIsVerified((prev) => prev + 1);
    toast.success(response?.message)
    setOtp('')
    setOpens(false)
  }

  const handleError = (error) => {
    toast.error(error?.data?.message)
  }

  const handleOks = async (otp, setOtp) => {
    try {
      if (!otp) {
        toast.error('Please enter a valid OTP')
        return
      }

      if (currentFn === 'email') {
        await handleEmailVerification(otp, setOtp)
      } else {
        await handlePhoneVerification(otp, setOtp)
      }
    } catch (error) {
      handleError(error)
    }
  }

  const handleCancels = () => {
    // setSeconds(59)
    // setMinutes(2)
    setOpens(false)
  }
  const handlePasteValue = (event) => {
    const sanitizedValue = handlePaste(event)

    signUpForm.setFields([
      {
        name: 'phoneNumber',
        value: sanitizedValue,
        errors: [''],
      },
    ])
    // signUpForm.setFieldValue('phoneNumber', sanitizedValue)
    const div = document.getElementById('phoneNumber')
    div.classList.remove('ant-input-status-error')

    event.preventDefault()
  }

  const onFinish = async (values) => {
    const data = {
      city: values?.city,
      confirmPassword: values?.confirmPassword,
      country: values?.country,
      countryCode: values?.countryCode,
      phoneCountry: values?.countryCode,
      dob: values?.dob,
      emailToken,
      name: values?.name,
      telegram: values?.telegram,
      password: values?.password,
      phoneNumberToken,
      remember: true,
      // dob: date,
      role,
    }
    try {
      if (emailToken && phoneNumberToken) {
        const response = await signup(data).unwrap()
        toast.success(response?.message)
        navigate(ROUTES.LOGIN)
        // setIsVerified(0)
      } else {
        toast.error('Email or Mobile number is not verified')
      }
    } catch (err) {
      toast.error(err?.data?.message)
      signUpForm.resetFields()
      setDate('')
      setPhoneToken('')
      setEmailToken('')
    }
  }

  const handleResponse = (response, verifyFn) => {
    const time = dayjs(response?.sendAt).add(180, 'seconds')
    setDeadline(time)
    toast.success(response?.message)
    setCurrenFn(verifyFn)
    setOpens(true)
  }

  const handleResendResponse = (response) => {
    const time = dayjs(response?.sendAt).add(180, 'seconds')
    setDeadline(time)
    toast.success(response?.message)
  }

  const verifyEmailFn = async () => {
    const email = signUpForm.getFieldValue('email')

    if (!email) {
      toast.warn('Please input email')
      return
    }

    try {
      const response = await sendOTPEmail({
        email: email.toLowerCase(),
      }).unwrap()
      handleResponse(response, 'email')
    } catch (error) {
      handleError(error)
    }
  }
  const verifyMobileFn = async () => {
    const phoneNumber = signUpForm.getFieldValue('phoneNumber')
    const countryCode = signUpForm.getFieldValue('countryCode')

    if (
      !phoneNumber ||
      !countryCode ||
      phoneNumber.length <= 5 ||
      phoneNumber.length >= 15
    ) {
      toast.warn('Please input a valid phone number and country code')
      return
    }

    try {
      const response = await sendOTPMobile({
        phoneNumber: Number(phoneNumber),
        countryCode,
      }).unwrap()

      handleResponse(response, 'phone')
    } catch (error) {
      handleError(error)
      toast.error(error?.data?.error)
    }
  }

  const getResendOTPResponse = async () => {
    let res
    if (currentFn === 'email') {
      const email = { email: signUpForm.getFieldValue('email').toLowerCase() }
      res = await sendOTPEmail(email).unwrap()
    } else {
      const phoneNumber = {
        phoneNumber: Number(signUpForm.getFieldValue('phoneNumber')),
        countryCode: signUpForm.getFieldValue('countryCode'),
      }
      res = await sendOTPMobile(phoneNumber).unwrap()
    }
    return res
  }

  const resendOTP = async () => {
    try {
      const response = await getResendOTPResponse()
      handleResendResponse(response)
    } catch (error) {
      handleError(error)
    }
  }
  useEffect(() => {
    signUpForm.setFieldValue('dob', dateRef?.current?.dateSelect?.dateValue)
    if (dateRef?.current?.dateSelect) {
      signUpForm.setFields([
        {
          name: 'dob',
          errors: [''],
        },
      ])
    }
  }, [dateRef?.current?.dateSelect, signUpForm])
  const countryChange = (item) => {
    setSelectedCountryCode(item?.isoCode)
    let code = ''
    if (item?.phoneCode[0] === '+') {
      code = item?.phoneCode
    } else {
      code = `+${item?.phoneCode}`
    }
    signUpForm.getFieldValue('city') && signUpForm.setFieldValue('city', null)
    signUpForm.setFieldValue('countryCode', code)
  }

  return (
    <StyledSignUp>
      {/* <Navbar /> */}
      <div className="signup-container">
        <div className="heading">
          Signup <span>{role}</span>
        </div>
        <Divider className="sign-line">Signup with Google</Divider>
        <GoogleLoginn />
        <Divider className="sign-line">or signup with email</Divider>
        <Form
          className="form"
          name="basic"
          // style={{
          //   maxWidth: 600,
          // }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          autoComplete="off"
          {...layout}
          // ref={formRef}
          form={signUpForm}
        >
          <Form.Item
            name="name"
            style={{ width: '100%' }}
            rules={[
              {
                required: true,
                message: 'Please input name',
              },
              {
                max: 50,
                message: 'Maximum 50 characters allowed',
              },
              {
                pattern: /[a-zA-Z]/,
                message: 'Must be a valid name',
              },
            ]}
          >
            <StyledLoginInput placeholder="Name" />
          </Form.Item>

          <Form.Item
            name="email"
            style={{ width: '100%' }}
            rules={[
              {
                required: true,
                message: 'Please input email',
              },
              // {
              //   type: 'email',
              //   message: 'Must be valid Email',
              // },
              () => ({
                validator(_, value) {
                  const decimalPattern =
                    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i

                  if (!value || decimalPattern.test(value)) {
                    return Promise.resolve()
                  }

                  return Promise.reject('Must be valid Email')
                },
              }),
            ]}
          >
            <StyledLoginInput
              placeholder="Your Email"
              disabled={!!emailToken}
              className={emailToken && 'verified'}
            />
          </Form.Item>
          <div className="verify-button">
            {!emailToken ? (
              <Button
                className="link-btn"
                onClick={verifyEmailFn}
                loading={sendEmailLoading}
              >
                Verify
              </Button>
            ) : (
              <Button
                className="link-btn"
                style={{ color: 'green', cursor: 'default' }}
              >
                Verified
              </Button>
            )}
          </div>
          <Form.Item
            id="telegram"
            name="telegram"
            style={{ width: '100%' }}
            rules={[
              {
                required: true,
                message: 'Please input telegram handle',
              },
              {
                whitespace: true,
                message: 'Initial space not allowed',
              },
            ]}
          >
            <StyledLoginInput maxLength={100} placeholder="Telegram" />
          </Form.Item>

          <Form.Item
            name="dob"
            rules={[
              {
                required: true,
                message: 'Please select the date of birth',
              },
            ]}
          >
            <div className="dob">
              <label>Birth Date</label>
              <CustomDateSelect
                value={date}
                form={signUpForm}
                onChange={setDate}
                yearFormat="yy"
                monthFormat="MMMM"
                dayFormat="do"
                defaultYear="now"
                ref={dateRef}
                defaultMonth="now"
                defaultDay="now"
                year={1900}
                month={0}
                SingleDate={1}
                lastYear={year}
                e={1}
                isTrue={false}
              />
            </div>
          </Form.Item>
          <Form.Item
            name="country"
            rules={[
              {
                required: true,
                message: 'Please select country',
              },
            ]}
          >
            <StyledSelect
              size="large"
              showSearch
              placeholder="Your Country"
              // onChange={onValueChange}
              allowClear
              onChange={(e, item) => {
                countryChange(item)
              }}
              options={countries}
            />
          </Form.Item>

          <Form.Item
            name="city"
            rules={[
              {
                required: true,
                message: 'Please select city',
              },
            ]}
          >
            <StyledSelect
              size="large"
              showSearch
              placeholder="Your City"
              // onChange={onValueChange}
              allowClear
              options={cities}
            />
          </Form.Item>
          <div className="phone-number-input">
            <Form.Item
              rules={[
                {
                  required: true,
                  message: 'Please input country code',
                },
              ]}
              name="countryCode"
            >
              <StyledLoginInput
                id="countryCode"
                placeholder="Country Code"
                disabled
              />
            </Form.Item>

            <Form.Item
              style={{ flex: 1 }}
              rules={[
                {
                  required: true,
                  message: 'Please enter phone number',
                },
                {
                  min: 6,
                  message: 'Minimum 6 digits required',
                },
                {
                  max: 15,
                  message: "Phone number can't be more than 15 digits",
                },
              ]}
              name="phoneNumber"
            >
              <StyledLoginInput
                size="large"
                id="phoneNumber"
                placeholder="Enter number"
                onPaste={handlePasteValue}
                type="number"
                disabled={!!phoneNumberToken}
                className={phoneNumberToken && 'verified'}
                onKeyDown={(e) => {
                  if (restrictedKeys.includes(e?.key)) {
                    e.preventDefault()
                  }
                }}
              />
            </Form.Item>
          </div>
          {/* {signUpForm?.getFieldError("phoneNumber").length === 0 && ( */}
          <div className="verify-button">
            {!phoneNumberToken ? (
              <Button
                className="link-btn"
                onClick={verifyMobileFn}
                loading={sendMobileLoading}
              >
                Verify
              </Button>
            ) : (
              <Button
                className="link-btn"
                style={{ color: 'green', cursor: 'default' }}
              >
                Verified
              </Button>
            )}
          </div>
          {/* )} */}
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: 'Please input password',
              },
              {
                message:
                  'Password must contain at least 8 characters, including one uppercase, one lowercase, one numeric & one special character',
                validator: validatePassword,
              },
            ]}
          >
            <StyledLoginPassword placeholder="Set Password" />
          </Form.Item>

          <Form.Item
            name="confirmPassword"
            rules={[
              {
                required: true,
                message: 'Please input confirm password',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve()
                  }
                  return Promise.reject(new Error('Password mismatch'))
                },
              }),
            ]}
          >
            <StyledLoginPassword placeholder="Confirm Password" />
          </Form.Item>
          <Form.Item className="checkbox-wrapper" name="remember">
            <Checkbox className="checkbox" required>
              I agree with the{' '}
              <a
                href="/assets/files/Terms&Conditions.pdf"
                target="_blank"
                rel="noreferrer"
              >
                general terms.
              </a>
            </Checkbox>
          </Form.Item>
          <StyledButton
            htmlType="submit"
            className="signup-btn"
            loading={signUpLoading}
          >
            Sign Up
          </StyledButton>
          <VerifyNumModal
            opens={opens}
            handleCancel={handleCancels}
            handleOks={handleOks}
            resendOTP={resendOTP}
            verifyEmailLoading={verifyEmailLoading}
            sendEmailLoading={sendEmailLoading}
            verifyMobileLoading={verifyMobileLoading}
            sendMobileLoading={sendMobileLoading}
            deadline={deadline}
          />
        </Form>
      </div>
    </StyledSignUp>
  )
}

export default SignUp
