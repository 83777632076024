import Web3 from 'web3'
import TOKEN_ABI from '../Assets/artifacts/Token.json'
import ZYNECASH_ABI from '../Assets/artifacts/Zynecash.json'

export const createInstance = ({ provider, address }) => {
  const web3 = new Web3(provider)
  const instance = new web3.eth.Contract(TOKEN_ABI, address)
  return instance
}

export const createCashInstance = ({ provider, address }) => {
  const web3 = new Web3(provider)
  const instance = new web3.eth.Contract(ZYNECASH_ABI, address)
  return instance
}

export const getName = async ({ provider, address }) => {
  const instance = createInstance({ provider, address })
  const name = await instance.methods.name().call()
  return name
}

export const getSymbol = async ({ provider, address }) => {
  const instance = createInstance({ provider, address })
  const symbol = await instance.methods.symbol().call()
  return symbol
}

export const getDecimal = async ({ provider, address }) => {
  const instance = createInstance({ provider, address })
  const decimals = await instance.methods.decimals().call()
  return decimals
}

export const getAllowance = async ({
  provider,
  address,
  tokenAddress,
  factoryAddress,
}) => {
  const instance = createInstance({ provider, address: tokenAddress })
  const allowance = await instance.methods
    .allowance(address, factoryAddress)
    .call()
  return allowance
}

export const getBalance = async ({ provider, address, tokenAddress }) => {
  const instance = createInstance({ provider, address: tokenAddress })
  const balance = await instance.methods.balanceOf(address).call()
  return balance
}

export const approve = async ({
  provider,
  address,
  tokenAddress,
  amount,
  factoryAddress,
}) => {
  const instance = createInstance({ provider, address: tokenAddress })
  const approved = await instance.methods
    .approve(factoryAddress, amount)
    .send({ from: address })
  return approved
}

export const getLockedBalance = async ({ provider, address, tokenAddress }) => {
  const instance = createCashInstance({ provider, address: tokenAddress })
  const lockedBalance = await instance.methods
    .lockedBalanceMapping(address)
    .call()

  return lockedBalance
}
