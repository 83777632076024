import { configureStore } from '@reduxjs/toolkit'
// Or from '@reduxjs/toolkit/query/react'
import { setupListeners } from '@reduxjs/toolkit/query'
import global from './Services/Auth'
import { publicWethioApi } from './Services/Public'
import { privateAuthApi } from './Services/PrivateAuth'
import { PublicApis } from './Services/PublicApis'

const Store = configureStore({
  reducer: {
    // Add the generated reducer as a specific top-level slice
    commonRed: global,
    [publicWethioApi.reducerPath]: publicWethioApi.reducer,
    [privateAuthApi.reducerPath]: privateAuthApi.reducer,
    [PublicApis.reducerPath]: PublicApis.reducer,
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false })
      .concat(publicWethioApi.middleware)
      .concat(PublicApis.middleware)
      .concat(privateAuthApi.middleware),
})

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(Store.dispatch)

export default Store
