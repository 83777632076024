import React from 'react'

function NotFound() {
  return (
    <div
      style={{
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        fontWeight: 'bold',
        fontSize: '35px',
        color: 'white',
      }}
    >
      <p>Page Not Found :(</p>
    </div>
  )
}

NotFound.propTypes = {}

export default NotFound
