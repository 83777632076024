import styled from 'styled-components'
import { deviceQuery } from '../../Constants/MediaSizes'

const StyledSignUp = styled.div`
  background: ${(props) => props.theme.colors.blue.dark};
  margin: auto;
  min-height: 100vh;
  font-family: 'Urbanist';
  font-style: normal;
  font-weight: 400 !important;
  font-size: 18px;
  line-height: 28px;
  padding: 20px 100px;
  padding-top: 150px;
  /*  Chrome, Safari, Edge, Opera  */
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /*  Firefox  */
  [type='number'] {
    -moz-appearance: textfield;
  }
  @media (${deviceQuery.tabletL}) {
    padding: 20px;
    padding-top: 150px;
  }
  /* padding-top: 200px;
  padding-bottom: 200px; */
  /* or 156% */
  .signup-container {
    width: 60%;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 100px;
    @media (${deviceQuery.tabletL}) {
      width: 80%;
    }
  }
  .ant-col {
    max-width: 100%;
  }
  .dob {
    display: flex;
    gap: 50px;
    /* justify-content: space-between; */
  }

  .sign-line {
    border: none;
    color: ${(props) => props.theme.colors.white} !important;
  }
  .sign-btn {
    font-size: 24px;
    color: ${(props) => props.theme.colors.white};
    height: 48px;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
  }
  .form {
    margin: auto;
    padding: 32px 0px;
  }
  .checkbox {
    color: ${(props) => props.theme.colors.white};
  }
  .ant-checkbox-wrapper {
    width: 100%;
  }
  .ant-select-arrow {
    color: ${(props) => props.theme.colors.white};
  }
  .signup-btn {
    height: 48px;
    background: linear-gradient(#7000ff, #1154ff 60%) !important;
    color: ${(props) => props.theme.colors.white} !important;
    font-size: 20px;
    border: none;
  }
  .ant-form-item .ant-form-item-control-input-content {
    text-align: left;
  }
  .ant-divider-inner-text {
    font-weight: 400;
    color: #ebebeb;
    font-size: 12px;
    font-family: 'Urbanist';
    font-style: normal;
    padding: 8px;
  }
  label {
    color: ${(prop) => prop.theme.colors.placeholder};
    font-family: 'Urbanist';
    font-style: normal;
    text-align: left;
    font-weight: 700;
    font-size: 12px;
    line-height: 22px;
    width: 80px;
  }
  .ant-form-item-explain-error {
    text-align: left;
  }
  .ant-select-selection-item {
    color: ${(prop) => prop.theme.colors.white};
  }
  .phone-number-input {
    display: flex;
    gap: 10px;
    justify-content: space-between;
    .ant-form-item {
      width: 40%;
      .ant-row {
        width: 100%;
        .ant-col {
          .ant-form-item-control-input {
            .ant-select {
              .ant-select-selector {
                width: 100%;
                margin: 0 !important;
                max-width: 100% !important;
              }
            }
          }
        }
      }
    }
    @media (${deviceQuery.mobileSM}) {
      flex-direction: column;

      .ant-form-item {
        width: 100%;
      }
    }
  }
  & .ant-select-selector > .ant-select-selection-search > input {
    color: #8a8aa0 !important;
  }
  .link-btn {
    border: none;
    background: ${(props) => props.theme.colors.blue.dark};
    color: #8a8aa0;
    cursor: pointer;
  }

  .ant-form-item .ant-form-item-control-input-content {
    text-align: right;
  }

  /* .ant-form-item{
    min-width: 0;
  } */

  /* .ant-form-item-explain-error {
    text-align: right;
  } */
  .verify-button {
    text-align: right;
    margin-top: -22px;
    margin-bottom: 20px;
  }

  .heading {
    font-size: 28px;
    font-weight: 700;
    color: ${(prop) => prop.theme.colors.white};
    line-height: 44px;
    margin-top: 50px;
    margin-bottom: 30px;
    text-transform: capitalize;
  }
  .verified {
    border: 1px solid green;
    color: ${(props) => props.theme.colors.white};
    :hover {
      border: 1px solid green;
    }
  }
  /* .ant-form-item
    > .ant-row
    > .ant-col
    > .ant-form-item-control-input
    > .ant-form-item-control-input-content
    > .ant-checkbox-wrapper
    > .ant-input {
    background: transparent !important;
    color: ${(prop) => prop.theme.colors.white};
  } */

  /* .ant-input-affix-wrapper >input.ant-input{
    background: transparent;
  } */
  #basic_countryCode_help > .ant-form-item-explain-error {
    max-width: 130px !important;
    text-align: left;
    @media (${deviceQuery.mobileSM}) {
      max-width: 100% !important;
    }
  }
  .ant-form-item-explain-error {
    text-align: left;
  }
`
export default StyledSignUp
