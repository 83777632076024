import { Modal } from 'antd'
import styled from 'styled-components'

const StyledWalletModal = styled(Modal)`
  color: ${(props) => props.theme.colors.white};

  .ant-modal-content {
    background-color: ${(props) => props.theme.colors.blue.semiLight};
    width: 100%;
    margin-top: 100px;
  }
  .ant-modal-header > .ant-modal-title {
    background-color: ${(props) => props.theme.colors.blue.semiLight};
    color: ${(props) => props.theme.colors.white};
  }
  .ant-modal-footer > .ant-btn.ant-btn-primary {
    background-color: ${(props) => props.theme.colors.blue.light} !important;
    color: ${(props) => props.theme.colors.blue.semiLight};
  }
  .ant-btn {
    padding: 7.5px 15px !important;
  }
  .ellipsis-wallet {
    white-space: nowrap;

    text-overflow: ellipsis;
    overflow: hidden;
    width: 90%;
  }
  button {
    height: 42px;
    width: 130px;
    border-radius: 72px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 1rem;
  }
  .connector-button,
  .connector-button:hover {
    border-color: ${(props) => props.theme.colors.blue.light};
    color: ${(props) => props.theme.colors.blue.light};
    width: 100%;
    margin: 1rem 0;
  }
  .not-wallet {
    width: 100%;
    display: flex;
    gap: 1rem;
    justify-content: center;
    a {
      color: ${(props) => props.theme.colors.white};
    }
    a:hover {
      color: ${(props) => props.theme.colors.blue.light};
    }
  }
  .copy {
    cursor: pointer;
    background-color: ${(props) => props.theme.colors.grey};
    padding: 0.5rem;
    border-radius: 12px;
  }
  .balance-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    .balance-fields {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 1rem;
      label {
        font-size: 1rem;
        font-weight: 600;
      }
      span {
        font-size: 1rem;
        font-weight: 600;
      }
    }
  }
  .modal-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    p {
      text-overflow: ellipsis;
      overflow: hidden;
      text-align: center;
      font-size: large;
      font-weight: bolder;
      margin: 0;
    }
  }
  .wallet-error {
    color: #f00;
  }
`

export default StyledWalletModal
