import { Drawer } from 'antd'
import styled from 'styled-components'

const StyledDrawer = styled(Drawer)`
  .button {
    max-width: 50%;
    border-color: ${(props) => props.theme.colors.blue.light};
    color: ${(props) => props.theme.colors.blue.light};
    span {
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 600;
      /* height: 50%; */
      width: 90%;
    }
  }
`

export default StyledDrawer
