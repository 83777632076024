/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-return-assign */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
// import { Divider } from 'antd'
// import { RightOutlined } from '@ant-design/icons'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import { LowerFooterContainer, StyledDivider } from './LowerFooter.Styles'
import World from '../../../Assets/World'
import Twitter from '../../../Assets/Twitter'
import Telegram from '../../../Assets/Telegram'
import Facebook from '../../../Assets/Facebook'
import FooterLogo from '../../../Assets/FooterLogo'
import ROUTES from '../../../Constants/Routes'

function LowerFooter() {
  const location = useLocation()
  return (
    <LowerFooterContainer>
      {location.pathname !== ROUTES.HOW_IT_WORKS &&
        location.pathname !== ROUTES.ABOUT_US && <StyledDivider />}
      <div className="top">
        <div className="social-link">
          <div className="logo">
            <FooterLogo />
            <span>MISTER&nbsp;Z</span>
          </div>
          <div className="links">
            <a
              href="https://www.wethioproject.com"
              target="_blank"
              rel="noreferrer"
              className="item"
              aria-label="a"
            >
              <World />
            </a>
            <a
              href="https://twitter.com/i/flow/login?redirect_after_login=%2Fwethio_project"
              target="_blank"
              rel="noreferrer"
              className="item"
              aria-label="a"
            >
              <Twitter />
            </a>
            <a
              href="https://t.me/zynecoinactu"
              target="_blank"
              rel="noreferrer"
              className="item"
              aria-label="a"
            >
              <Telegram />
            </a>
            <a
              href="https://www.facebook.com/wethioprojectofficiel"
              target="_blank"
              rel="noreferrer"
              className="item"
              aria-label="a"
            >
              <Facebook />
            </a>
          </div>

          {/* <div className="newsletter">
            <span>Sign up to our news letter</span>
            <Divider
              type="vertical"
              style={{
                height: '96%',
                width: '1.5px',
                backgroundColor: '#7000FF',
              }}
            />
            <div className="btn-right">
              <RightOutlined style={{ fontSize: '22px' }} />
            </div>
          </div> */}
        </div>
        <div className="about-section">
          <div className="company item-container">
            <div className="heading">Company</div>
            <a href={ROUTES.ABOUT_US} className="item">
              About
            </a>
            <a
              href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=marketing@zynecoin.io"
              target="_blank"
              rel="noreferrer"
              className="item"
            >
              Press Contact
            </a>

            <Link to="/#partners" className="item">
              Sponsors
            </Link>
          </div>
          <div className="resource item-container">
            <div className="heading">Resource</div>
            <div>Media Kit (coming soon)</div>
            <a
              href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=support@zynecoin.io"
              target="_blank"
              rel="noreferrer"
              className="item"
            >
              Support
            </a>

            <a href="/" className="item">
              White Paper
            </a>
          </div>
          <div className="useful-link item-container">
            <div className="heading">Useful Links</div>
            <a
              href="https://www.wethioproject.com/"
              target="_blank"
              rel="noreferrer"
              className="item"
            >
              Wethio Project
            </a>
            <a
              href="https://wethio.io/"
              target="_blank"
              rel="noreferrer"
              className="item"
            >
              Wethio Blockchain
            </a>
          </div>
        </div>
      </div>
      <div className="content">
        This web page and any other contents published on this website shall not
        constitute investment advice, financial advice, trading advice, or any
        other kind of advice, and you should not treat any of the website&apos;s
        content as such. You alone assume the sole responsibility of evaluating
        the merits and risks associated with using any information or other
        content on this website before making any decisions based on such
        information. You understand that the crypto market is characterised by
        high volatility, and you should be aware of the concrete possibility of
        losing the entirety of the funds you allocated in the crypto market. You
        should refrain from using funds you can&aps;t afford to lose when
        purchasing cryptocurrencies and other digital tokens.
      </div>
      <div className="condition">
        <div className="copyright">
          Copyright © 2023. All Rights Reserved by Wethio Project
        </div>
        <div className="version">V 1.0</div>
        <div className="policy">
          <span className="privacy">Privacy</span>
          <a
            href="/assets/files/Terms&Conditions.pdf"
            target="_blank"
            rel="noreferrer"
            className="terms"
          >
            Terms of
          </a>
        </div>
      </div>
    </LowerFooterContainer>
  )
}

export default LowerFooter
