import styled from 'styled-components'
import { Divider } from 'antd'
import { deviceQuery } from '../../../Constants/MediaSizes'

export const LowerFooterContainer = styled.div`
  margin: auto;
  width: 90%;
  color: ${(props) => props.theme.colors.white};
  padding: 40px 0px;
  .pointer {
    cursor: pointer;
  }

  .top {
    display: grid;
    grid-template-columns: 1fr 3fr;
    justify-content: center;
    gap: 40px;
    margin-bottom: 40px;
    @media (${deviceQuery.tabletL}) {
      grid-template-columns: 1fr;
    }
    .social-link {
      display: flex;
      flex-direction: column;
      gap: 20px;
      @media (${deviceQuery.mobileSMT}) {
        align-items: center;
      }
      .logo {
        display: flex;
        align-items: center;
        gap: 20px;
        @media (${deviceQuery.mobileXS}) {
          flex-direction: column;
        }
        span {
          font-family: 'Montserrat', sans-serif;
          font-size: 48px;
        }
      }
      .links {
        display: flex;
        gap: 18px;
      }
      .newsletter {
        display: flex;
        align-items: center;
        background-color: ${(props) => props.theme.colors.white};
        color: ${(props) => props.theme.colors.blue.dark};
        border-radius: 6px;
        height: 45px;
        max-width: 275px;
        span {
          line-height: 22px;
          font-size: 14px;
          font-weight: 600;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding-left: 12px;
          padding-right: 20px;
        }
        .btn-right {
          cursor: pointer;
        }
      }
    }
    .about-section {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      justify-content: center;
      align-items: flex-start;
      width: 100%;
      @media (${deviceQuery.mobileM}) {
        grid-template-columns: 1fr 1fr;
        gap: 20px;
      }
      @media (${deviceQuery.mobileSMT}) {
        grid-template-columns: 1fr;
        gap: 20px;
      }
    }
    .item-container {
      display: flex;
      flex-direction: column;
      gap: 20px;
      align-items: start;
      @media (${deviceQuery.mobileM}) {
        align-items: center;
      }
    }
    .heading {
      font-weight: 700;
      font-size: 28px;
      line-height: 50px;
      color: ${(props) => props.theme.colors.blue.grad1};
    }
    .item {
      font-size: 16px;
      font-weight: 400;
      text-decoration: none;
      color: white;
      &:hover {
        color: #7000ff;
      }
    }
  }
  .content {
    text-align: justify;
    font-size: 12px;
    line-height: 20px;
  }
  .condition {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    @media (${deviceQuery.tabletMS}) {
      flex-direction: column;
      justify-content: start;
      align-items: start;
      gap: 20px;
    }
    .policy {
      display: flex;
      gap: 30px;
      text-decoration: underline;
      color: #ffffff;
      .terms {
        color: #ffffff;
        cursor: pointer;
      }
      .privacy {
        cursor: pointer;
      }
    }
  }
`

export const StyledDivider = styled(Divider)`
  background-color: ${(props) => props.theme.colors.blue.grad1};
  height: 4.79px;
`
