import styled from 'styled-components'
import { deviceQuery } from '../../Constants/MediaSizes'

const NavContainer = styled.nav`
  /* background: transparent; */
  position: absolute;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px 100px 20px 100px;
  z-index: 100;
  @media (${deviceQuery.laptopM}) {
    padding: 20px 50px;
  }
  /* padding: 20px 70px; */
  .left-nav-container {
    display: flex;
    align-items: center;
    gap: 40px;
    a {
      color: ${(props) => props.theme.colors.white};
      text-decoration: none;
      font-weight: 700;
    }
    @media (${deviceQuery.laptopM}) {
      gap: 30px;
    }
  }
  .right-nav-container {
    display: flex;
    align-items: center;
    gap: 1.5rem;

    .left-button {
      border-color: ${(props) => props.theme.colors.blue.light};
      color: ${(props) => props.theme.colors.white};
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 600;
        height: 50%;
        width: 90%;
      }

      height: 60px;
      font-weight: 700;
      width: 200px;

      @media (${deviceQuery.desktopXL}) {
        height: 40px;
        width: 180px;
        /* font-size: 16px; */
        span {
          height: 55%;
        }
      }
    }

    .right-button {
      background: linear-gradient(to right, #7000ff, #1154ff 60%);
      color: ${(props) => props.theme.colors.white};
      border: none;
    }
    button {
      height: 42px;
      width: 130px;
      border-radius: 72px;
    }
  }
  .mobile-icon {
    display: none;
    .hamburger-icon {
      width: 24px;
      height: 24px;
      color: ${(props) => props.theme.colors.white};
    }
  }
  @media (${deviceQuery.tabletL}) {
    .left-nav-container {
      display: none;
    }
    .right-nav-container {
      display: none;
    }
    .mobile-icon {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
    }
  }
`

export default NavContainer
