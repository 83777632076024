export const ROLE = {
  CLIENT: 'client',
  EXPERT: 'expert',
}

export const INVESTMENT_TYPE = {
  ZYN: 'Zynecoin',
  ZYNCH: 'Zynecash',
}

export const PROPOSAL_STATUS = {
  Active: 'active',
  Canceled: 'canceled',
  Defeated: 'defeated',
  Succeeded: 'succeeded',
  Queued: 'queued',
  Expired: 'expired',
  Executed: 'executed',
  Accepted: 'accepted',
  Rejected: 'rejected',
}

export const PROJECT_ENUM = {
  UnderReview: 'under-review',
  Freezed: 'freezed',
  Delist: 'delist',
  Removed: 'removed',
  Active: 'active',
  dislike: 'dislike',
  InitialInvestment: 'initialInvestmentDone',
  Rejected: 'rejected',
  Withdraw: 'withdraw',
}

export const PROPOSAL_ACTION = {
  Withdraw: 'withdraw',
  Revoke: 'revoke',
}

export const MESSAGE = {
  wallet: 'Please Connect your Wallet!',
  initialSpace: 'Initial space not allowed',
  balance: 'Insufficient Balance',
}

export const VALIDATIONMSG = {
  title: 'Please input title',
  percent: 'Please input percentage',
  range: 'Please enter value between 0 and 100',
  positive:
    'Please enter a valid positive decimal value up to 2 decimal places.',
  positiveGTOne:
    'Please enter a valid positive decimal value up to 2 decimal places and greater than 1.',
  description: 'Please input description',
  GTzero: 'Please enter value greater than 0',
}
