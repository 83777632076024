import { Button, Input } from 'antd'
import styled from 'styled-components'
import { deviceQuery } from '../../Constants/MediaSizes'

export const StyledForgotForm = styled.div`
  width: 80%;
  margin: auto;
  display: flex;
  flex-direction: column;
  padding-top: 150px;
  gap: 20px;
  /*  Chrome, Safari, Edge, Opera  */
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /*  Firefox  */
  [type='number'] {
    -moz-appearance: textfield;
  }

  h4 {
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 34px;
    /* or 122% */

    text-align: center;
    text-transform: capitalize;

    /* On Surface */

    color: ${(props) => props.theme.colors.white};
  }
  p {
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    /* identical to box height, or 133% */
    text-align: center;
    letter-spacing: 0.02em;

    color: #79869f;
  }

  .forgot-form {
    width: 50%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 70px;
    .phone-number-input {
      display: flex;
      gap: 10px;
      @media (${deviceQuery.mobileS}) {
        flex-direction: column;
      }
    }
  }

  .form {
    text-align: right;
  }

  .ant-select-selector > .ant-select-selection-item {
    color: ${(props) => props.theme.colors.white};
  }
  @media (${deviceQuery.laptopM}) {
    .forgot-form {
      padding: 20px;
    }
  }
  @media (${deviceQuery.laptopS}) {
    .forgot-form {
      width: 100%;
    }
  }

  .ant-select > .ant-select-arrow {
    color: ${(props) => props.theme.colors.white};
  }
  #basic_countryCode_help > .ant-form-item-explain-error {
    max-width: 130px !important;
    text-align: left;
    @media (${deviceQuery.mobileS}) {
      max-width: 100% !important;
    }
  }
  .ant-form-item-explain-error {
    text-align: left;
  }
  /* @media (${deviceQuery.mobileSM}) {
    max-width: 100% !important;
  } */
  .phone-input {
    margin-bottom: 0px;
    width: 100%;
    @media (${deviceQuery.mobileS}) {
      width: 100%;
    }
  }
`

export const StyledButton = styled(Button)`
  width: 100%;
  background: linear-gradient(to right, #7000ff, #1154ff 60%);
  /* border: 1px solid ${(props) => props.theme.colors.blue.light}; */
  border-radius: 56px !important;
  border: none;
  font-family: 'Urbanist';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 22px;
  /* or 122% */

  text-align: center;

  color: ${(props) => props.theme.colors.white};
`
export const StyledInput = styled(Input)`
  background: ${(props) => props.theme.colors.blue.dark};
  /* height: 55.87px; */
  color: ${(props) => props.theme.colors.white};
  border-color: #343444;
  height: 40px;
  &::placeholder {
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #8a8aa0;
    text-align: left;
  }
`
