const config = {
  BASE_URL: process.env.REACT_APP_BASE_URL,
  MASTERFACTORY_CONTRACT: process.env.REACT_APP_MASTERFACTORY_CONTRACT,
  ADMIN_CONTRACT: process.env.REACT_APP_ADMIN_CONTRACT,
  ZYNCASH: process.env.REACT_APP_ZYNCASH_ADDRESS,
  GOVERNANCE_CONTRACT: process.env.REACT_APP_GOVERNANCE_CONTRACT,
  WETHIO_URL: process.env.REACT_APP_WETHIO_URL,
  ZYNCOIN: process.env.REACT_APP_ZERO_ADDRESS,
  ZYNPRICE: process.env.REACT_APP_ZYN_PRICE_ADDRESS,
}

export default config
