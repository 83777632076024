import React from 'react'

function Passcode() {
  return (
    <div>
      <svg
        width="160"
        height="160"
        viewBox="0 0 160 160"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id="mask0_1345_701"
          style={{ 'mask-type': 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="160"
          height="160"
        >
          <path
            d="M80 160C124.183 160 160 124.183 160 80C160 35.8172 124.183 0 80 0C35.8172 0 0 35.8172 0 80C0 124.183 35.8172 160 80 160Z"
            fill="#D9E8FF"
          />
        </mask>
        <g mask="url(#mask0_1345_701)">
          <path
            d="M80 160C124.183 160 160 124.183 160 80C160 35.8172 124.183 0 80 0C35.8172 0 0 35.8172 0 80C0 124.183 35.8172 160 80 160Z"
            fill="#34DACF"
          />
          <path
            d="M154.871 95.728C128.954 96.9185 109.517 111.107 103.038 118.053C93.9675 118.053 72.457 116.713 58.9805 111.355C42.1348 104.658 11.683 100.937 1.96432 86.7986C-5.81061 75.4876 -22.4403 74.1482 -29.7833 74.8923V118.053L95.9114 131.447L213.831 118.053V59.2651C191.154 60.7534 187.267 94.2398 154.871 95.728Z"
            fill="#4518FF"
          />
          <rect
            x="0.480469"
            y="118.468"
            width="213.126"
            height="77.1406"
            fill="#7000FF"
          />
          <g filter="url(#filter0_d_1345_701)">
            <path
              d="M128.713 42.1738H36.1914C30.5391 42.1738 25.957 46.6913 25.957 52.2638V155.62C25.957 161.192 30.5391 165.71 36.1914 165.71H128.713C134.365 165.71 138.947 161.192 138.947 155.62V52.2638C138.947 46.6913 134.365 42.1738 128.713 42.1738Z"
              fill="#00625B"
            />
          </g>
          <path
            d="M125.548 40.7368H32.7661C27.0979 40.7368 22.5029 45.3318 22.5029 50.9999V156.13C22.5029 161.798 27.0979 166.393 32.7661 166.393H125.548C131.216 166.393 135.811 161.798 135.811 156.13V50.9999C135.811 45.3318 131.216 40.7368 125.548 40.7368Z"
            fill="url(#paint0_linear_1345_701)"
          />
          <path
            d="M80.2716 65.0276C91.6377 65.0276 100.852 55.8135 100.852 44.4474C100.852 33.0812 91.6377 23.8672 80.2716 23.8672C68.9055 23.8672 59.6914 33.0812 59.6914 44.4474C59.6914 55.8135 68.9055 65.0276 80.2716 65.0276Z"
            fill="#4518FF"
          />
          <path
            d="M80.2712 43.6666C83.3981 43.6666 85.933 41.0996 85.933 37.9329C85.933 34.7663 83.3981 32.1992 80.2712 32.1992C77.1443 32.1992 74.6094 34.7663 74.6094 37.9329C74.6094 41.0996 77.1443 43.6666 80.2712 43.6666Z"
            fill="white"
          />
          <path
            d="M85.1244 44.6531C84.83 44.5116 84.5064 44.442 84.1799 44.4498C83.8534 44.4576 83.5334 44.5427 83.2461 44.698C82.3241 45.1704 81.303 45.4168 80.2669 45.4168C79.2309 45.4168 78.2098 45.1704 77.2878 44.698C77.0005 44.5427 76.6805 44.4576 76.354 44.4498C76.0275 44.442 75.7039 44.5116 75.4095 44.6531C73.9322 45.386 72.6342 46.435 71.6076 47.7256C70.581 49.0163 69.8509 50.5169 69.4691 52.1212C69.3933 52.4298 69.3882 52.7515 69.454 53.0624C69.5199 53.3732 69.655 53.6652 69.8494 53.9166C70.0437 54.1679 70.2923 54.3722 70.5766 54.5141C70.8608 54.6561 71.1735 54.7321 71.4912 54.7365H89.0877C89.406 54.7338 89.7195 54.6589 90.0047 54.5175C90.2898 54.376 90.5391 54.1717 90.7338 53.9199C90.9285 53.6681 91.0635 53.3754 91.1286 53.0638C91.1938 52.7523 91.1873 52.43 91.1097 52.1212C90.7248 50.512 89.9885 49.0079 88.9537 47.7167C87.9189 46.4255 86.6112 45.3793 85.1244 44.6531Z"
            fill="white"
          />
          <path
            d="M49.2305 78.1864L110.468 78.1055"
            stroke="#4518FF"
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M54.6494 92.1684L102.766 92.1055"
            stroke="#4518FF"
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M49.2305 110.186L110.468 110.105"
            stroke="#4518FF"
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M54.6494 124.168L102.766 124.105"
            stroke="#4518FF"
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M159.374 135.504C159.163 135.305 158.915 135.15 158.644 135.047C158.373 134.944 158.084 134.896 157.795 134.905C157.505 134.915 157.22 134.981 156.956 135.101C156.692 135.22 156.454 135.391 156.256 135.602L142.11 150.728L136.862 144.437C136.474 144.027 135.945 143.781 135.382 143.749C134.819 143.716 134.265 143.901 133.833 144.263C133.402 144.626 133.125 145.14 133.06 145.7C132.995 146.26 133.146 146.824 133.483 147.277L140.025 155.077C140.136 155.302 140.285 155.506 140.466 155.679C140.877 156.06 141.416 156.271 141.975 156.273H142.056C142.575 156.272 143.077 156.091 143.476 155.76C143.659 155.598 143.816 155.411 143.944 155.203L159.491 138.586C159.877 138.158 160.081 137.597 160.059 137.021C160.037 136.445 159.792 135.901 159.374 135.504Z"
            fill="#4518FF"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_1345_701"
            x="17.957"
            y="38.1738"
            width="128.99"
            height="139.536"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="4" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_1345_701"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_1345_701"
              result="shape"
            />
          </filter>
          <linearGradient
            id="paint0_linear_1345_701"
            x1="79.1569"
            y1="40.7368"
            x2="79.4305"
            y2="206.651"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset="1" stopColor="white" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  )
}

export default Passcode
