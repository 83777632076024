/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-expressions */
import { useDateSelect } from 'react-ymd-date-select'
import styled from 'styled-components'
import dayjs from 'dayjs'
import React, { forwardRef, useImperativeHandle } from 'react'
import { FEB_MONTH, LONG_MONTHS, SHORT_MONTHS } from '../../Constants/Date'
import { StyledSelect } from '../../GlobalStyles'
import { deviceQuery } from '../../Constants/MediaSizes'
import { checkLeapYear } from '../../Utils/Common'

const StyledDateSelect = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
  height: 100%;
  @media (${deviceQuery.mobileM}) {
    flex-direction: column;
  }
`
const CustomDateSelect = forwardRef((props, ref) => {
  const dateSelect = useDateSelect(props.value, props.onChange, {
    firstYear: props.year,
    lastYear: props.lastYear ?? props.year + 10,
    monthFormat: props.monthFormat,
  })
  const d = dayjs().add(1, 'days')

  const month = d.month()
  // console.log(month, d, 'month')
  const date = d.date()
  useImperativeHandle(ref, () => ({ dateSelect }), [dateSelect])
  return (
    <StyledDateSelect>
      <StyledSelect
        value={dateSelect.yearValue}
        onChange={(e) => {
          if (dateSelect.monthValue || dateSelect.dayValue) {
            dateSelect.setDate('')
            props?.e === 1 && props?.form.setFieldValue('dob', '')
          }

          dateSelect.onYearChange(e)
          props?.isTrue && props.setIsSelected(true)
        }}
      >
        <option value="" disabled>
          Year
        </option>
        {dateSelect.yearOptions.map((yearOption) => (
          <option key={yearOption.value} value={yearOption.value}>
            {yearOption.label}
          </option>
        ))}
      </StyledSelect>

      <StyledSelect
        value={dateSelect.monthValue}
        onChange={(e) => {
          if (dateSelect.dayValue) {
            dateSelect.onDayChange('')
          }
          dateSelect.onMonthChange(e)
          props?.isTrue && props.setIsSelected(true)
        }}
      >
        <option value="" disabled>
          Month
        </option>
        {dateSelect.monthOptions
          .filter((monthOption) => {
            if (props.e === 1) {
              if (dateSelect.yearValue !== '') return monthOption
            } else if (props.e === 2) {
              if (Number(dateSelect.yearValue) === props.year) {
                if (Number(monthOption.value) >= month + 1) {
                  return monthOption
                }
              } else if (Number(dateSelect.yearValue) !== props?.lastYear) {
                return monthOption
              }
            } else {
              return monthOption
            }
          })
          .map((monthOption) => (
            <option key={monthOption.value} value={monthOption.value}>
              {monthOption.label}
            </option>
          ))}
      </StyledSelect>

      <StyledSelect
        value={dateSelect.dayValue}
        onChange={(e) => {
          dateSelect.onDayChange(e)
          props?.isTrue && props.setIsSelected(true)
        }}
      >
        <option value="" disabled>
          Date
        </option>
        {dateSelect.dayOptions
          .filter((dayOption) => {
            if (props.e === 1) {
              if (
                checkLeapYear(Number(dateSelect?.yearValue)) &&
                FEB_MONTH.indexOf(Number(dateSelect?.monthValue)) !== -1
              ) {
                if ([30, 31]?.indexOf(Number(dayOption.value)) === -1) {
                  return dayOption
                }
              } else if (
                SHORT_MONTHS.indexOf(Number(dateSelect?.monthValue)) !== -1
              ) {
                if (Number(dayOption.value) !== 31) {
                  return dayOption
                }
              } else if (
                LONG_MONTHS.indexOf(Number(dateSelect?.monthValue)) !== -1
              ) {
                return dayOption
              } else if (
                FEB_MONTH.indexOf(Number(dateSelect?.monthValue)) !== -1
              ) {
                if ([29, 30, 31]?.indexOf(Number(dayOption.value)) === -1) {
                  return dayOption
                }
              }
            } else if (props.e === 2) {
              if (Number(dateSelect.yearValue) === props.year) {
                if (Number(dateSelect.monthValue) === props.month + 1) {
                  if (Number(dayOption.value) >= date) {
                    if (
                      SHORT_MONTHS.indexOf(Number(dateSelect?.monthValue)) !==
                      -1
                    ) {
                      if (Number(dayOption.value) !== 31) {
                        return dayOption
                      }
                    } else if (
                      LONG_MONTHS.indexOf(Number(dateSelect?.monthValue)) !== -1
                    ) {
                      return dayOption
                    } else if (
                      FEB_MONTH.indexOf(Number(dateSelect?.monthValue)) !== -1
                    ) {
                      if (
                        [29, 30, 31]?.indexOf(Number(dayOption.value)) === -1
                      ) {
                        return dayOption
                      }
                    }
                  }
                } else if (
                  SHORT_MONTHS.indexOf(Number(dateSelect?.monthValue)) !== -1
                ) {
                  if (Number(dayOption.value) !== 31) {
                    return dayOption
                  }
                } else if (
                  LONG_MONTHS.indexOf(Number(dateSelect?.monthValue)) !== -1
                ) {
                  return dayOption
                } else if (
                  FEB_MONTH.indexOf(Number(dateSelect?.monthValue)) !== -1
                ) {
                  if ([29, 30, 31]?.indexOf(Number(dayOption.value)) === -1) {
                    return dayOption
                  }
                }
              } else if (
                checkLeapYear(Number(dateSelect?.yearValue)) &&
                FEB_MONTH.indexOf(Number(dateSelect?.monthValue)) !== -1
              ) {
                if ([30, 31]?.indexOf(Number(dayOption.value)) === -1) {
                  return dayOption
                }
              } else if (
                SHORT_MONTHS.indexOf(Number(dateSelect?.monthValue)) !== -1
              ) {
                if (Number(dayOption.value) !== 31) {
                  return dayOption
                }
              } else if (
                LONG_MONTHS.indexOf(Number(dateSelect?.monthValue)) !== -1
              ) {
                return dayOption
              } else if (
                FEB_MONTH.indexOf(Number(dateSelect?.monthValue)) !== -1
              ) {
                if ([29, 30, 31]?.indexOf(Number(dayOption.value)) === -1) {
                  return dayOption
                }
              }
            } else {
              return dayOption
            }
          })
          .map((dayOption) => (
            <option key={dayOption.value} value={dayOption.value}>
              {dayOption.label}
            </option>
          ))}
      </StyledSelect>
    </StyledDateSelect>
  )
})

export default CustomDateSelect
