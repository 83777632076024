import styled from 'styled-components'

const GoogleContainer = styled.div`
  /* margin: auto;
    font-size: 24px;
    border-color: ${(props) => props.theme.colors.blue.light};
    color: ${(prop) => prop.theme.colors.white};
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .nsm7Bb-HzV7m-LgbsSe-bN97Pc-sM5MNb{
        color: white !important;
    }
    .nsm7Bb-HzV7m-LgbsSe{
        background-color: transparent !important;
    }
    .nsm7Bb-HzV7m-LgbsSe{
        border-color: ${(props) => props.theme.colors.blue.light};

    } */
  .google-btn {
    margin: auto;
    font-size: 24px;
    border-color: ${(props) => props.theme.colors.blue.light};
    color: ${(prop) => prop.theme.colors.white};
    height: 48px;

    width: 100%;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    :hover {
      border-color: ${(props) => props.theme.colors.blue.light};
      color: ${(prop) => prop.theme.colors.white};
    }
  }
`

export default GoogleContainer
