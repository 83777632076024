import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Button } from 'antd'
import Web3 from 'web3'
import StyledWalletModal from './WalletModal.styles'
import { copyFunction, getSubstring } from '../../Utils/Common'
import {
  getBalance,
  getDecimal,
  getLockedBalance,
} from '../../Utils/tokenContract'
import config from '../../configuration/Config'
import useContextApp from '../../Hooks/useContextApp'

function WalletModal({
  open,
  handleCancel,
  handleOk,
  disconnect,
  walletAddress,
}) {
  const [balance, setBalance] = useState({
    zynbal: 0,
    zynchbal: 0,
    unlockedZynchBal: 0,
  })

  const { webProvider } = useContextApp()

  const web3Instance = useMemo(() => new Web3(webProvider), [webProvider])

  const getBalances = useCallback(async () => {
    if (walletAddress && webProvider) {
      let bal = await web3Instance?.eth.getBalance(walletAddress[0])
      bal = web3Instance?.utils.fromWei(bal.toString(), 'ether').slice(0, 8)
      // let bal1 = await getBalance({
      //   provider: webProvider,
      //   address: walletAddress[0],
      //   tokenAddress: config?.ZYNCASH,
      // })
      // let lockedBal = await getLockedBalance({
      //   provider: webProvider,
      //   address: walletAddress[0],
      //   tokenAddress: config?.ZYNCASH,
      // })
      // const decimal = await getDecimal({
      //   provider: webProvider,
      //   address: config?.ZYNCASH,
      // })
      // const value =
      //   Number(decimal) === 18
      //     ? Web3.utils.fromWei(bal1.toString(), 'ether')
      //     : String(bal1.toString() / 10 ** decimal)
      // const lockValue =
      //   Number(decimal) === 18
      //     ? Web3.utils.fromWei(lockedBal.toString(), 'ether')
      //     : String(lockedBal.toString() / 10 ** decimal)
      // let amountbn = Web3.utils.toBN(bal1);
      // let decimals = Web3.utils.toBN(10 ** decimal);
      // bal1 = amountbn.div(decimals);
      // bal1 = Web3.utils.toHex(bal1);
      // bal1 = Web3.utils.hexToNumber(bal1);
      // lockedBal = value - lockValue
      // bal1 = value
      setBalance({
        zynbal: bal,
        // zynchbal: bal1,
        // unlockedZynchBal: lockedBal,
      })
    }
  }, [walletAddress, web3Instance, webProvider])

  useEffect(() => {
    if (walletAddress && webProvider) {
      getBalances()
    }
  }, [getBalances, walletAddress, webProvider])

  const component = () => {
    if (walletAddress) {
      return (
        <div className="modal-container">
          <p className="ellipsis-wallet">{getSubstring(walletAddress[0])}</p>
          <span
            className="copy"
            onClick={() => copyFunction(walletAddress[0])}
            aria-hidden
          >
            Copy to ClipBoard
          </span>
          <div className="balance-container">
            <div className="balance-fields">
              <label>ZyneCoin Balance:</label>
              <span>{Number(balance?.zynbal).toFixed(2)}</span>
            </div>
            {/* <div className="balance-fields">
              <label>ZyneCash Balance:</label>
              <span>
                {Number(balance?.zynchbal).toFixed(2)}(
                {Number(balance?.unlockedZynchBal).toFixed(2)})
              </span>
            </div> */}
          </div>
          <Button ghost className="connector-button" onClick={disconnect}>
            Disconnect
          </Button>
        </div>
      )
    }
    return (
      <div className="modal-container">
        <Button ghost className="connector-button" onClick={handleOk}>
          MetaMask
          <img src="/assets/metamask.png" alt="metamask" width={22} />
        </Button>

        <div className="not-wallet">
          <a href="https://metamask.io/" target="_blank" rel="noreferrer">
            Don&apos;t have a wallet?
          </a>
        </div>
      </div>
    )
  }

  return (
    <StyledWalletModal
      title="Connect Wallet"
      destroyOnClose
      open={open}
      footer={null}
      onCancel={handleCancel}
      onOk={handleOk}
    >
      {component()}
    </StyledWalletModal>
  )
}

export default WalletModal
