import { React, useCallback, useEffect, useState } from 'react'
import { Button } from 'antd'
import { RxHamburgerMenu } from 'react-icons/rx'
import { useNavigate } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import Web3 from 'web3'
import NavContainer from './NavContainer.styles'
import ROUTES from '../../Constants/Routes'
import { StyledNavlink } from '../../GlobalStyles'
import Logo from '../../Assets/Logo'
import CustomDrawer from '../CustomDrawer'
import WalletModal from '../WalletModal'
import DisconnectButton from '../DisconnectButton'
import ConnectionButton from '../ConnectionButton'
import {
  setAddress,
  setIsLogin,
  setProvider,
  setToken,
} from '../../Services/Auth'
import { getAccount, walletConnectUser } from '../../Utils/walletAuth'
import {
  getLocalStorageItem,
  removeLocalStorageItem,
  setLocalStorageItem,
} from '../../Utils/localStorage'
import {
  useGetUserDataQuery,
  useLazyGetSignMessageQuery,
  useUpdateWalletAddressMutation,
} from '../../Services/Users'
import { useLogoutMutation } from '../../Services/PrivateAuth'
import useContextApp from '../../Hooks/useContextApp'
import { checkIfLogin } from '../../Utils/Common'

function Navbar() {
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const { isLogin, address } = useSelector((state) => state.commonRed)
  const { data: userData } = useGetUserDataQuery({}, { skip: !isLogin })
  const [walletOpen, setWalletOpen] = useState(false)
  const [loginCheck, setLoginCheck] = useState(false)
  const {
    walletAddress: userWallet,
    setWalletAddress,
    setWebProvider,
  } = useContextApp()
  const [getSignMessage] = useLazyGetSignMessageQuery()
  const [updateWalletAddress] = useUpdateWalletAddressMutation()

  const showDrawer = () => {
    setOpen(true)
  }
  const onClose = () => {
    setOpen(false)
  }
  const dispatch = useDispatch()

  const walletClose = () => {
    setWalletOpen(false)
  }

  const disconnect = useCallback(() => {
    removeLocalStorageItem('userwalletAddress')
    removeLocalStorageItem('signature')
    dispatch(setAddress(''))
    dispatch(setProvider({}))
    setWalletAddress('')
    setWebProvider({})
    setWalletOpen(false)
  }, [dispatch, setWalletAddress, setWebProvider])

  const connect = async () => {
    try {
      const walletType = window?.ethereum ? 'METAMASK' : 'walletconnect'

      const message = await getSignMessage().unwrap()
      await window.web3.currentProvider.enable()
      const web3 = new Web3(window.web3.currentProvider)
      const { walletAddress, signature } = await walletConnectUser(
        walletType,
        web3,
        message.signingMessage
      )

      /* 'need to update when we use walletconnect' */

      dispatch(setProvider(window.web3.currentProvider))
      setWebProvider(window.web3.currentProvider)
      if (walletAddress[0] === userData?.walletAddress) {
        setWalletAddress(walletAddress)
        dispatch(setAddress(walletAddress))

        setLocalStorageItem('userwalletAddress', walletAddress)
      } else if (walletAddress && signature) {
        await updateWalletAddress({
          signature,
          walletAddress: walletAddress[0],
        }).unwrap()

        setWalletAddress(walletAddress)
        dispatch(setAddress(walletAddress))

        setLocalStorageItem('userwalletAddress', walletAddress)
      } else {
        toast.error('Unable to connect to metamask')
      }
      setWalletOpen(false)
    } catch (e) {
      console.error(e)
      disconnect()
      toast.error(e?.data?.message)
    }
  }

  const [logout] = useLogoutMutation()
  const LogOut = async () => {
    try {
      const response = await logout().unwrap()
      localStorage.clear()
      dispatch(setToken(''))
      dispatch(setIsLogin(false))
      toast.success(response?.message)
      navigate(ROUTES.HOME)
      disconnect()
      onClose()
    } catch (error) {
      toast.error(error?.data?.message)
    }
  }

  const updateAccount = useCallback(async () => {
    try {
      const userWalletAddress = getLocalStorageItem('userwalletAddress')
      const signature = getLocalStorageItem('signature')

      if (userWalletAddress && signature) {
        await window.web3.currentProvider.enable()
        const web3 = new Web3(window.web3.currentProvider)

        dispatch(setProvider(window.web3.currentProvider))
        setWebProvider(window.web3.currentProvider)
        getAccount(web3).then((data) => {
          setAddress(data)
          if (data) {
            setAddress(data)
            setLocalStorageItem('userwalletAddress', data)
            setLocalStorageItem('userwalletAddress', JSON.stringify(data))
            dispatch(setAddress(data))
          }
        })
      }
    } catch (error) {
      disconnect()
    }
  }, [disconnect, dispatch, setWebProvider])

  useEffect(() => {
    if (window?.ethereum) {
      window?.ethereum?.on('accountsChanged', () => {
        disconnect()
      })
    }
  }, [disconnect])

  useEffect(() => {
    setWalletAddress(address)
  }, [address, setWalletAddress])

  useEffect(() => {
    if (window?.ethereum) {
      updateAccount()
    }
  }, [updateAccount])

  useEffect(() => {
    const val = checkIfLogin()
    setLoginCheck(val)
  })

  return (
    <>
      <NavContainer>
        <div className="left-nav-container">
          <StyledNavlink to={ROUTES.HOME}>
            <Logo />
          </StyledNavlink>
          <StyledNavlink to={ROUTES.HOW_IT_WORKS}>How It Works</StyledNavlink>
          <StyledNavlink to={ROUTES.PROJECTS}>Submitted Ideas</StyledNavlink>
          <StyledNavlink to={ROUTES.ABOUT_US}>About Us</StyledNavlink>
        </div>
        <div className="right-nav-container">
          {isLogin && loginCheck ? (
            <>
              <Button
                className="left-button"
                ghost
                shape="round"
                onClick={() => setWalletOpen(true)}
                // onClick={() => (walletAddress ? disconnect() : connect())}
              >
                <span>{userWallet || 'Connect Wallet'}</span>
              </Button>

              <DisconnectButton userData={userData} LogOut={LogOut} />
            </>
          ) : (
            // <Button onClick={() => navigate(ROUTES.APPLICATIONS)}>
            //   Connection
            // </Button>
            <ConnectionButton />
          )}
        </div>
        <div className="mobile-icon">
          <StyledNavlink to={ROUTES.HOME}>
            <Logo />
          </StyledNavlink>
          <RxHamburgerMenu className="hamburger-icon" onClick={showDrawer} />
        </div>
        <CustomDrawer
          onClose={onClose}
          open={open}
          userData={userData}
          LogOut={LogOut}
          setWalletOpen={setWalletOpen}
        />
      </NavContainer>
      <WalletModal
        open={walletOpen}
        handleCancel={walletClose}
        handleOk={connect}
        disconnect={disconnect}
        walletAddress={userWallet}
      />
    </>
  )
}

export default Navbar
