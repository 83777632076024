import React from 'react'

function Frame({ height = 102, width = 98 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 69 65"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.55"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M68.899 35.5966C68.899 45.0523 64.7912 53.467 58.3914 59.453C56.4293 61.288 53.4609 63.3377 50.9014 64.3056C49.0336 65.0106 46.9837 65.0705 45.0778 64.476C43.1719 63.8815 41.5197 62.6667 40.3839 61.0248C39.2481 59.3829 38.6941 57.4084 38.81 55.4153C38.9259 53.4222 39.7051 51.5252 41.0235 50.026C42.8933 47.9032 44.9394 45.7 46.8548 43.5759C54.0377 35.6097 62.9862 26.3879 58.8076 11.9248C62.0024 14.9835 64.5439 18.6582 66.2784 22.7268C68.0129 26.7955 68.9044 31.1736 68.899 35.5966V35.5966Z"
        fill="url(#paint0_linear_1_251)"
      />
      <path
        opacity="0.55"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.10697 35.4034C4.10697 25.9477 8.21477 17.533 14.6146 11.547C16.5767 9.71195 19.5451 7.66225 22.1045 6.69437C23.9726 5.99043 26.0224 5.93142 27.9278 6.52675C29.8333 7.12207 31.4849 8.33753 32.6199 9.97973C33.7549 11.6219 34.3081 13.5965 34.1915 15.5894C34.0749 17.5823 33.2952 19.4789 31.9765 20.9775C30.1067 23.1004 28.0606 25.3036 26.1452 27.4277C18.9622 35.3938 10.0138 44.6157 14.1924 59.0788C10.9982 56.0192 8.45758 52.3436 6.72411 48.2744C4.99064 44.2051 4.10033 39.8265 4.10697 35.4034V35.4034Z"
        fill="url(#paint1_linear_1_251)"
      />
      <path
        opacity="0.55"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.9854 64.7074C46.5627 64.823 47.15 64.8809 47.7388 64.8801C49.6698 64.8801 51.9066 64.0741 53.5473 63.0235C55.9628 57.7367 56.09 52.36 55.0933 48.1274C53.3962 40.9193 48.4968 35.4142 43.4799 29.9188C39.1466 25.1729 34.7929 21.4261 34.3444 15.9606C34.2316 14.5766 34.1429 13.2945 33.6583 11.9788C33.0257 10.2615 31.8815 8.7795 30.3803 7.73272C28.8791 6.68594 27.093 6.1247 25.2628 6.12469C23.5046 6.12469 22.2237 6.58165 20.6225 7.34684C20.5026 7.40561 19.663 7.83498 19.4447 7.9789C17.0292 13.2645 16.9021 18.6424 17.8988 22.8749C19.5959 30.0831 24.4952 35.5881 29.5122 41.0836C32.5441 44.4046 35.5857 47.2363 37.2936 50.5106C37.779 51.5632 38.1722 52.6559 38.469 53.7764C38.5531 54.1936 38.6131 54.6153 38.6489 55.0393C38.7616 56.4234 38.8504 57.7055 39.3349 59.0212C39.8652 60.4583 40.7551 61.7353 41.9196 62.7304C43.0842 63.7255 44.4844 64.4053 45.9866 64.705L45.9854 64.7074Z"
        fill="url(#paint2_linear_1_251)"
      />
      <path
        opacity="0.55"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.10697 35.5966C4.10697 45.0523 8.21477 53.467 14.6145 59.453C16.5767 61.288 19.5451 63.3377 22.1045 64.3056C23.972 65.0081 26.0207 65.0663 27.925 64.4709C29.8293 63.8754 31.4799 62.6605 32.6146 61.0193C33.7492 59.3781 34.3027 57.4048 34.187 55.4129C34.0714 53.421 33.2933 51.525 31.9765 50.026C30.1067 47.9032 28.0606 45.6999 26.1452 43.5747C18.9622 35.6097 10.0138 26.3879 14.1924 11.9248C10.9985 14.9839 8.45808 18.6588 6.72462 22.7275C4.99116 26.7961 4.10067 31.174 4.10697 35.5966V35.5966Z"
        fill="url(#paint3_linear_1_251)"
      />
      <path
        opacity="0.55"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M68.8989 35.4035C68.8989 25.9477 64.7911 17.533 58.3913 11.547C56.4291 9.71199 53.4607 7.66228 50.9013 6.6944C49.0329 5.98803 46.9818 5.92719 45.0748 6.52158C43.1678 7.11597 41.5146 8.3314 40.3784 9.97428C39.2422 11.6171 38.6885 13.593 38.8054 15.587C38.9223 17.5811 39.703 19.4787 41.0234 20.9776C42.8932 23.1004 44.9393 25.3037 46.8546 27.4277C54.0376 35.3939 62.986 44.6157 58.8075 59.0788C62.0024 56.0202 64.544 52.3455 66.2785 48.2769C68.013 44.2082 68.9044 39.83 68.8989 35.4071V35.4035Z"
        fill="url(#paint4_linear_1_251)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1_251"
          x1="34.5422"
          y1="52.6838"
          x2="70.487"
          y2="27.5153"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6E00F7" />
          <stop offset="0.49" stopColor="#4A51FF" />
          <stop offset="1" stopColor="#00A9FF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1_251"
          x1="2.51302"
          y1="43.4847"
          x2="38.459"
          y2="18.3162"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00A9FF" />
          <stop offset="0.51" stopColor="#4A51FF" />
          <stop offset="1" stopColor="#6E00F7" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1_251"
          x1="60.055"
          y1="55.2696"
          x2="12.937"
          y2="15.7328"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4518FF" />
          <stop offset="0.17" stopColor="#2B5FEB" />
          <stop offset="0.4" stopColor="#0CB4D3" />
          <stop offset="0.5" stopColor="#00D6C9" />
          <stop offset="0.55" stopColor="#03CECB" />
          <stop offset="0.64" stopColor="#0BB6D2" />
          <stop offset="0.74" stopColor="#1991DD" />
          <stop offset="0.86" stopColor="#2C5CEC" />
          <stop offset="1" stopColor="#4518FF" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1_251"
          x1="37.5859"
          y1="55.1641"
          x2="2.42067"
          y2="25.6575"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4A3BFF" />
          <stop offset="0.2" stopColor="#2964F4" />
          <stop offset="0.41" stopColor="#0B8AEB" />
          <stop offset="0.53" stopColor="#0098E7" />
          <stop offset="1" stopColor="#00FFBF" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1_251"
          x1="70.5792"
          y1="45.3425"
          x2="35.4152"
          y2="15.8359"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00FFBF" />
          <stop offset="0.47" stopColor="#0098E7" />
          <stop offset="0.59" stopColor="#0B8AEB" />
          <stop offset="0.8" stopColor="#2964F4" />
          <stop offset="1" stopColor="#4A3BFF" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default Frame
