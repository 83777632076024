import React from 'react'
import { useLocation } from 'react-router'
import Navbar from '../Navbar'
import ROUTES from '../../Constants/Routes'

function Layout({ children }) {
  const { pathname } = useLocation()

  return (
    <main className="layout-main">
      {![ROUTES.EDITRATINGS, ROUTES.RATINGS].some((path) =>
        pathname.startsWith(path)
      ) && <Navbar />}
      {children}
    </main>
  )
}

export default Layout
