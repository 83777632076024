/* eslint-disable react/jsx-one-expression-per-line */
import { Button, Input } from 'antd'
import React, { useEffect, useState } from 'react'
import Passcode from '../../Assets/Passcode'
import { StyledOtpInput, StyledVerifyNumModal } from './VerifyNumModal.styles'
import { StyledCustomTimer } from '../../GlobalStyles'

// const { Countdown } = Statistic

function VerifyNumModal({
  opens,
  handleOks,
  handleCancel,
  resendOTP,
  verifyEmailLoading,
  sendEmailLoading,
  verifyMobileLoading,
  sendMobileLoading,
  deadline,
}) {
  const [otp, setOtp] = useState()
  const [timer, setTimer] = useState(false)
  // const [minutes, setMinutes] = useState(2)
  // const [seconds, setSeconds] = useState(59)

  const onFinish = () => {
    setTimer(false)
  }

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     if (seconds > 0) {
  //       setSeconds(seconds - 1)
  //     }

  //     if (seconds === 0) {
  //       if (minutes === 0) {
  //         clearInterval(interval)
  //       } else {
  //         setSeconds(59)
  //         setMinutes(minutes - 1)
  //       }
  //     }
  //   }, 1000)

  //   return () => {
  //     clearInterval(interval)
  //   }
  // }, [seconds, minutes])

  useEffect(() => {
    if (opens) {
      // setMinutes(2)
      // setSeconds(59)
      setOtp(null)
    }
  }, [opens])

  useEffect(() => {
    if (deadline) {
      setTimer(true)
    }
  }, [deadline])

  return (
    <StyledVerifyNumModal
      title="OTP Verification"
      destroyOnClose
      open={opens}
      footer={null}
      onCancel={() => handleCancel()}
      onOk={handleOks}
    >
      <div className="modal-container">
        <div className="form-img">
          <Passcode />
        </div>
        <StyledOtpInput
          value={otp}
          containerStyle={{
            gap: '50px',
          }}
          inputStyle={{
            color: 'white',
            background: '#0D1122',
            border: 'none',
            borderBottom: '1px solid #8A8AA0',
            borderRadius: 0,
            width: '20%',
            backgroundColor: 'Transparent',
          }}
          onChange={setOtp}
          numInputs={4}
          inputType="number"
          renderInput={(props) => (
            <Input type="number" {...props} className="otp-input" />
          )}
        />
        {/* {(seconds > 0 || minutes > 0) && (
          <div className="timer-container">
            <span className="timer-disabled">Resend code in: </span>
            <span className="timer">
              {minutes && minutes < 10 ? `0${minutes}` : minutes}:
              {seconds < 10 ? `0${seconds}` : seconds}
            </span>
          </div>
        )} */}
        {timer && (
          <div className="timer-container">
            <span className="timer-disabled">Resend code in: </span>
            <span className="timer">
              <StyledCustomTimer
                value={deadline}
                format="mm:ss"
                onFinish={onFinish}
              />
            </span>
          </div>
        )}
        <div className="btns">
          <Button
            disabled={timer}
            onClick={() => {
              resendOTP()
              setOtp(undefined)
            }}
            loading={sendEmailLoading || sendMobileLoading}
          >
            Resend Code
          </Button>
          <Button
            onClick={() => handleOks(otp, setOtp)}
            loading={verifyEmailLoading || verifyMobileLoading}
          >
            Submit
          </Button>
        </div>
      </div>
    </StyledVerifyNumModal>
  )
}

export default VerifyNumModal
