import { Button } from 'antd'
import React from 'react'
import { useNavigate } from 'react-router'
import NavbarIcon from '../../Assets/NavbarIcon'
import StyledDisconnect from './StyledDisconnect.styles'
import ROUTES from '../../Constants/Routes'
import constants from '../../Constants/constants'

function DisconnectButton({ userData, LogOut }) {
  const navigate = useNavigate()

  return (
    <StyledDisconnect>
      <div
        className="profile"
        onClick={() => {
          navigate(ROUTES.PROFILE)
          // onClose()
        }}
        aria-hidden
      >
        {userData?.image && userData?.image !== constants.defaultUrl ? (
          <img src={userData?.image} alt="" height="40px" width="40px" />
        ) : (
          <NavbarIcon />
        )}
      </div>
      <div className="name">
        <p onClick={() => navigate(ROUTES.PROFILE)} aria-hidden>
          {userData?.name ?? 'N/A'}
        </p>
        <Button className="disconnect-btn" onClick={() => LogOut()}>
          Log Out
        </Button>
      </div>
    </StyledDisconnect>
  )
}

export default DisconnectButton
