import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query'
import config from '../configuration/Config'
import { setIsLogin, setToken } from './Auth'
import { getLocalStorageItem, setLocalStorageItem } from '../Utils/localStorage'
import { publicBaseQuery } from './PublicApis'

export const rtkBaseQuery = fetchBaseQuery({
  baseUrl: config.BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    const { token } = getState().commonRed
    if (token) {
      headers.set('Authorization', `Bearer ${token}`)
    }
    return headers
  },
})

export const baseQueryWithReauth = async (args, api, extraOptions) => {
  const result = await rtkBaseQuery(args, api, extraOptions)
  if (result.error && result.error.status === 401) {
    api.dispatch(setIsLogin(false))

    localStorage.clear()
    // window.location.href = "/"
  }
  // console.log(result, 'result')
  if (result.error && result.error?.data?.message === 'Session Expired ') {
    const token = getLocalStorageItem('refreshToken')
    if (token) {
      api.dispatch(setIsLogin(false))
      // console.log(api, extraOptions, 'heeee')
      const refreshResult = await publicBaseQuery(
        {
          url: '/auth/refresh',
          method: 'POST',
          body: {
            refreshToken: token,
          },
        },
        api,
        extraOptions
      )
      // console.log(refreshResult, 'hgsjh')
      setLocalStorageItem('accessToken', refreshResult?.data?.accessToken)
      api.dispatch(setToken(refreshResult?.data?.accessToken))
      api.dispatch(setIsLogin(true))
      // await rtkBaseQuery({
      //   url: '/users/me',
      //   method: 'GET',
      //   headers: {
      //     Authorization: `Bearer ${refreshResult?.data?.accessToken}`
      //   }
      // }, api, extraOptions)
    } else {
      api.dispatch(setIsLogin(false))
      api.dispatch(setToken(''))
      localStorage.clear()
    }
  }
  return result
}
