import styled from 'styled-components'
import { Button } from 'antd'
import { deviceQuery } from '../../Constants/MediaSizes'

export const StyledButton = styled(Button)`
  width: 100%;
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: ${(props) => props.theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-transform: capitalize;
  background: linear-gradient(to right, #7000ff, #1154ff 60%);
  border-radius: 70px;
  padding-top: 20px;
  padding-bottom: 20px;
`

export const StyledMessage = styled.div`
  display: flex;
  flex-direction: column;

  .nav-apply {
    display: grid;
    grid-template-columns: 1fr 2fr;
    align-items: left;
    gap: 15px;
    padding: 40px 0px;
    width: 80%;
    margin: auto;

    .nav-title {
      display: flex;
      gap: 20px;
      align-items: center;
    }
    .nav-logo {
      text-align: left;
      padding-left: 32%;
    }
  }
  .main-body {
    width: 80%;
    align-items: center;
    margin: auto;
    display: grid;
    gap: 40px;
    grid-template-columns: 1fr 1fr;

    .body-img {
      background: url('/assets/MessageImg.png') no-repeat center;
      background-size: cover;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .title-details {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 60%;
        margin: auto;
        gap: 20px;

        h2 {
          font-family: 'Oswald';
          font-style: normal;
          font-weight: 700;
          font-size: 40px;
          line-height: 47px;
          /* or 118% */

          text-align: center;
          text-transform: capitalize;

          /* Surface main */

          color: ${(props) => props.theme.colors.white};
        }
        p {
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 400;
          font-size: 21px;
          line-height: 34px;
          /* or 162% */

          text-align: center;
          text-transform: capitalize;

          /* Surface main */

          color: ${(props) => props.theme.colors.white};
        }
      }
    }
    .body-form {
      text-align: left;
      h3 {
        font-family: 'Urbanist';
        font-style: normal;
        font-weight: 700;
        font-size: 28px;
        line-height: 44px;
        /* identical to box height, or 122% */

        text-transform: capitalize;

        /* On Surface */

        color: ${(props) => props.theme.colors.white};
      }
      p {
        font-family: 'Urbanist';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 28px;
        /* or 156% */

        /* Secondary */

        color: #ebebeb;
      }
    }
    .message-form {
      p {
        text-align: center;
      }
    }
  }
  .acc-desc {
    margin-top: 8px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: ${(props) => props.theme.colors.white};
    a {
      text-decoration: underline;
    }
  }
  @media (${deviceQuery.laptopL}) {
    .main-body {
      grid-template-columns: 1fr;
    }
  }
`
