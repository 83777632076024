import { message } from 'antd'
import { isJwtExpired } from 'jwt-check-expiration'
import { toast } from 'react-toastify'
import { getLocalStorageItem } from './localStorage'
// import rateData from '../Constants/commonConstants'

export const checkIfLogin = () => {
  const token = getLocalStorageItem('accessToken')
  return !!token && !isJwtExpired(token)
}

export const getSubstring = (str) => {
  if (str?.length > 10) {
    const firstPart = str.substring(0, 5)
    const lastPart = str.substring(str.length - 8)

    return `${firstPart}...${lastPart}`
  }
  return str
}

export const copyFunction = (value) => {
  navigator.clipboard.writeText(value)
  toast.info('Copied successfully')
}

export const epochDate = (value) => {
  const myDate = new Date(value)
  return Number((myDate.getTime() / 1000).toFixed(0))
}

export const extractVideoId = (url) => {
  let videoId = null
  const urlObj = new URL(url)

  if (
    urlObj.hostname === 'www.youtube.com' ||
    urlObj.hostname === 'youtube.com'
  ) {
    if (urlObj.pathname === '/watch') {
      const searchParams = new URLSearchParams(urlObj.search)
      videoId = searchParams.get('v')
    } else if (urlObj.pathname.startsWith('/embed/')) {
      videoId = urlObj.pathname.substring(7)
    } else if (urlObj.pathname.startsWith('/live/')) {
      videoId = urlObj.pathname.substring(6)
    }
  } else if (urlObj.hostname === 'youtu.be') {
    videoId = urlObj.pathname.substring(1)
  }
  return videoId
}

// program to check leap year
export const checkLeapYear = (year) => {
  // three conditions to find out the leap year
  if (year % 100 === 0 ? year % 400 === 0 : year % 4 === 0) {
    return true
  }
  return false
}

export const handlePaste = (event) => {
  const pastedValue = event.clipboardData.getData('text/plain')

  // Remove non-numeric characters from the pasted value
  const sanitizedValue = pastedValue.replace(/[^\d]/g, '')

  // // Set the sanitized value as the input value
  // event.target.value = sanitizedValue

  // Prevent the default paste behavior
  event.preventDefault()

  return sanitizedValue
}

export function isString(value) {
  return Object.prototype.toString.call(value) === '[object String]'
}

export const checkFileSize = (file) => {
  const isSizeValid = file.size <= 102400
  if (!isSizeValid) {
    message.error('File size should be less than or equal to 100KB.')
  }
  return isSizeValid
}

export const checkFileSize1 = (file) => {
  const isSizeValid = file.size <= 409600
  if (!isSizeValid) {
    message.error('File size should be less than or equal to 400KB.')
  }
  return isSizeValid
}

export const aggregateFn = (rating) => {
  // console.log(rating, 'aggref')
  let sum = 0
  sum =
    rating.vision +
    rating.product +
    rating.team +
    rating.roadmap +
    rating.whitepaper +
    rating.technical +
    rating.financial +
    rating.token +
    rating.security +
    rating.compliance
  // console.log(sum, 'aggreff')
  return sum / 10
}
