/* eslint-disable no-unused-vars */
import React, {
  // createContext,
  useEffect,
  useState,
  useCallback,
  // useMemo,
} from 'react'
import { ThemeProvider } from 'styled-components'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { useDispatch, useSelector } from 'react-redux'
import { Spin } from 'antd'
import { ToastContainer, toast } from 'react-toastify'
// import { isJwtExpired } from 'jwt-check-expiration'
import './App.css'
// import { useNavigate } from 'react-router'
import AppRoutes from './Router/AppRoutes'
import theme from './Constants/Theme'
import 'react-toastify/dist/ReactToastify.css'
import {
  setAddress,
  setIsLogin,
  setProvider,
  setToken,
  // useLogoutMutation,
  // useRefreshTokenMutation,
} from './Services/Auth'
import {
  useGetClientIdQuery,
  // useRefreshTokenMutation,
} from './Services/PublicApis'
import 'animate.css'
import { getChainId } from './Utils/walletAuth'
import wethioChain from './configuration/wethioChain'
import {
  getLocalStorageItem,
  removeLocalStorageItem,
  // setLocalStorageItem,
} from './Utils/localStorage'
// import ROUTES from './Constants/Routes'
import Layout from './Components/Layout'
import useContextApp from './Hooks/useContextApp'
import { useGetUserDataQuery } from './Services/Users'
// import { useRefreshTokenMutation } from "./Services/PrivateAuth";
// import { useGetUserDataQuery } from "./Services/Users";

// export const AddressContext = createContext()

function App() {
  const [currentTheme] = useState('light')
  const { token } = useSelector((state) => state.commonRed)
  // const [loading, setLoading] = useState({ load: false, message: '' })
  // const [walletAddress, setWalletAddress] = useState('')
  // const [webProvider, setWebProvider] = useState({})
  // const [skip, setSkip] = useState(true)
  const { loading, setWalletAddress, setWebProvider } = useContextApp()
  // const navigate = useNavigate()
  const { data: clientData } = useGetClientIdQuery()
  // const { data: userData } = useGetUserDataQuery({}, { skip })
  // const [refreshToken] = useRefreshTokenMutation()
  const dispatch = useDispatch()
  const istoken = getLocalStorageItem('accessToken')
  if (istoken && !token) {
    dispatch(setToken(istoken))
    dispatch(setIsLogin(true))
  }

  // For future use

  // const disconnect = useCallback(() => {
  //   removeLocalStorageItem("userwalletAddress");
  //   removeLocalStorageItem("signature");
  //   dispatch(setAddress(""));
  //   dispatch(setProvider({}));
  //   setWalletAddress("");
  //   setWebProvider({});
  // }, [dispatch, setWalletAddress, setWebProvider]);

  // const [logout] = useLogoutMutation();
  // const LogOut = async () => {
  //   try {
  //     const response = await logout().unwrap();
  //     localStorage.clear();
  //     dispatch(setToken(""));
  //     dispatch(setIsLogin(false));
  //     toast.success(response?.message);
  //     navigate(ROUTES.HOME);
  //     disconnect();
  //   } catch (error) {
  //     toast.error(error?.data?.message);
  //   }
  // };
  const chainChanged = useCallback(async () => {
    const currentChainId = await getChainId()

    if (currentChainId !== wethioChain.id) {
      removeLocalStorageItem('userwalletAddress')
      removeLocalStorageItem('signature')
      dispatch(setAddress(''))
      dispatch(setProvider({}))
      setWalletAddress('')
      setWebProvider({})
    }
  }, [dispatch])

  useEffect(() => {
    if (typeof window.ethereum === 'undefined') {
      toast.warn('Please install metamask')
    } else {
      window.ethereum.on('chainChanged', () => {
        chainChanged()
      })
    }
  }, [chainChanged, dispatch])

  // const accessTkn = localStorage.getItem('accessToken')

  // const refreshTokenFn = useCallback(
  //   async (tokenVal) => {
  //     setIsLogin(false)
  //     const response = await refreshToken({ refreshToken: tokenVal }).unwrap()
  //     setLocalStorageItem('accessToken', response?.accessToken)
  //     setIsLogin(true)
  //   },
  //   [refreshToken]
  // )

  // useEffect(() => {
  //   const refreshTkn = getLocalStorageItem('refreshToken')
  //   const isExpired = token && isJwtExpired(token)
  //   console.log('helo')
  //   if (isExpired) {
  //     if (refreshTkn) {
  //       refreshTokenFn(refreshTkn)
  //     } else {
  //       localStorage.clear()
  //       dispatch(setToken(''))
  //       dispatch(setIsLogin(false))
  //       navigate(ROUTES.HOME)
  //     }
  //   }
  //   // else {
  //   //   localStorage.clear();
  //   //   dispatch(setToken(""));
  //   //   dispatch(setIsLogin(false));
  //   // }
  // }, [dispatch, navigate, refreshTokenFn, token])

  // useEffect(() => {
  //   if (userDataa) {
  //     setUserData(userDataa);
  //   }
  // }, [userDataa]);
  // useEffect(() => {
  //   if (userData?.isBlocked) {
  //     LogOut();
  //   }
  // }, [userData]);

  // useEffect(() => {
  //   if (isLogin) {
  //     setSkip(false)
  //   } else {
  //     setSkip(true)
  //   }
  // }, [isLogin])

  // const AddressContextValue = useMemo(
  //   () => ({
  //     walletAddress,
  //     setWalletAddress,
  //     webProvider,
  //     setWebProvider,
  //     loading,
  //     setLoading,
  //   }),
  //   [
  //     walletAddress,
  //     setWalletAddress,
  //     webProvider,
  //     setWebProvider,
  //     loading,
  //     setLoading,
  //   ]
  // )

  return (
    <GoogleOAuthProvider
      clientId={clientData?.clientId ? clientData?.clientId : '401'}
    >
      {/* <AddressContext.Provider value={AddressContextValue}> */}
      {loading.load && (
        <div className="screen-loader">
          <Spin size="large" tip={loading.message}>
            <div className="spin-content" />
          </Spin>
        </div>
      )}
      <div
        className="app"
        style={{
          backgroundColor:
            currentTheme === 'light'
              ? theme.light?.colors?.blue?.dark
              : theme?.dark?.colors?.blue?.dark,
        }}
      >
        <ThemeProvider
          theme={currentTheme === 'light' ? theme.light : theme.dark}
        >
          <Layout>
            <AppRoutes />
          </Layout>
          <ToastContainer />
        </ThemeProvider>
      </div>
      {/* </AddressContext.Provider> */}
    </GoogleOAuthProvider>
  )
}

export default App
