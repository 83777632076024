import React from 'react'

function Create() {
  return (
    <svg
      width="160"
      height="160"
      viewBox="0 0 160 160"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_1345_880"
        style={{ 'mask-type': 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="160"
        height="160"
      >
        <path
          d="M80 160C124.183 160 160 124.183 160 80C160 35.8172 124.183 0 80 0C35.8172 0 0 35.8172 0 80C0 124.183 35.8172 160 80 160Z"
          fill="#D9E8FF"
        />
      </mask>
      <g mask="url(#mask0_1345_880)">
        <path
          d="M80 160C124.183 160 160 124.183 160 80C160 35.8172 124.183 0 80 0C35.8172 0 0 35.8172 0 80C0 124.183 35.8172 160 80 160Z"
          fill="#6BF6FF"
        />
        <path
          d="M154.871 95.728C128.954 96.9185 109.517 111.107 103.038 118.053C93.9675 118.053 72.457 116.713 58.9805 111.355C42.1348 104.658 11.683 100.937 1.96432 86.7986C-5.81061 75.4876 -22.4403 74.1482 -29.7833 74.8923V118.053L95.9114 131.447L213.831 118.053V59.2651C191.154 60.7534 187.267 94.2398 154.871 95.728Z"
          fill="#4518FF"
        />
        <rect
          x="0.480469"
          y="118.468"
          width="213.126"
          height="77.1406"
          fill="#7000FF"
        />
        <circle cx="79.543" cy="83.2227" r="44.2227" fill="white" />
        <rect
          x="61.4521"
          y="78.02"
          width="36.1822"
          height="28.1417"
          rx="2"
          fill="#4518FF"
        />
        <path
          d="M69.6035 80.7876V69.7511C69.6035 64.5227 73.842 60.2842 79.0704 60.2842V60.2842C84.2988 60.2842 88.5373 64.5227 88.5373 69.7511V80.7876"
          stroke="#4518FF"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  )
}

export default Create
