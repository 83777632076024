import React from 'react'

function Forgot() {
  return (
    <svg
      width="160"
      height="160"
      viewBox="0 0 160 160"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_1345_529"
        style={{ 'mask-type': 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="160"
        height="160"
      >
        <path
          d="M80 160C124.183 160 160 124.183 160 80C160 35.8172 124.183 0 80 0C35.8172 0 0 35.8172 0 80C0 124.183 35.8172 160 80 160Z"
          fill="#D9E8FF"
        />
      </mask>
      <g mask="url(#mask0_1345_529)">
        <path
          d="M80 160C124.183 160 160 124.183 160 80C160 35.8172 124.183 0 80 0C35.8172 0 0 35.8172 0 80C0 124.183 35.8172 160 80 160Z"
          fill="#6BF6FF"
        />
        <path
          d="M154.871 95.728C128.954 96.9185 109.517 111.107 103.038 118.053C93.9675 118.053 72.457 116.713 58.9805 111.355C42.1348 104.658 11.683 100.937 1.96432 86.7986C-5.81061 75.4876 -22.4403 74.1482 -29.7833 74.8923V118.053L95.9114 131.447L213.831 118.053V59.2651C191.154 60.7534 187.267 94.2398 154.871 95.728Z"
          fill="#4518FF"
        />
        <rect
          x="0.480469"
          y="118.468"
          width="213.126"
          height="77.1406"
          fill="#7000FF"
        />
        <path
          d="M38 44C38 38.4772 42.4772 34 48 34H111.084C116.606 34 121.084 38.4772 121.084 44V164.473C121.084 168.891 117.502 172.473 113.084 172.473H46C41.5817 172.473 38 168.891 38 164.473V44Z"
          fill="#22325C"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M43.1934 45.1929C43.1934 41.8792 45.8797 39.1929 49.1934 39.1929H109.892C113.205 39.1929 115.891 41.8792 115.891 45.1929V166.473C115.891 169.786 113.205 172.473 109.891 172.473H49.1934C45.8796 172.473 43.1934 169.786 43.1934 166.473V45.1929Z"
          fill="white"
        />
        <circle cx="79.5423" cy="91.2127" r="24.2327" fill="#34DACF" />
        <rect
          x="69.6289"
          y="88.3618"
          width="19.8268"
          height="15.4208"
          rx="2"
          fill="white"
        />
        <path
          d="M74.0957 89.8783V83.8306C74.0957 80.9656 76.4183 78.6431 79.2833 78.6431V78.6431C82.1483 78.6431 84.4709 80.9656 84.4709 83.8306V89.8783"
          stroke="white"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M63.9639 131.785H95.1202"
          stroke="#A5B4CB"
          strokeWidth="3.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity="0.5"
          d="M67.4258 142.17H91.6585"
          stroke="#A5B4CB"
          strokeWidth="3.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M60.502 38.6035H98.5819L95.3868 45.5167C94.8961 46.5783 93.8331 47.2581 92.6636 47.2581H66.4203C65.2508 47.2581 64.1878 46.5783 63.6971 45.5167L60.502 38.6035Z"
          fill="#22325C"
        />
      </g>
    </svg>
  )
}

export default Forgot
