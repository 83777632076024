import React, { createContext, useMemo, useState } from 'react'

export const ContextApp = createContext(null)

function ContextAppProvider({ children }) {
  const [loading, setLoading] = useState({ load: false, message: '' })
  const [walletAddress, setWalletAddress] = useState('')
  const [webProvider, setWebProvider] = useState({})

  const AddressContextValue = useMemo(
    () => ({
      walletAddress,
      setWalletAddress,
      webProvider,
      setWebProvider,
      loading,
      setLoading,
    }),
    [
      walletAddress,
      setWalletAddress,
      webProvider,
      setWebProvider,
      loading,
      setLoading,
    ]
  )

  return (
    <ContextApp.Provider value={AddressContextValue}>
      {children}
    </ContextApp.Provider>
  )
}

export default ContextAppProvider
