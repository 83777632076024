import { Modal } from 'antd'
import OTPInput from 'react-otp-input'
import styled from 'styled-components'

export const StyledVerifyNumModal = styled(Modal)`
  /*  Chrome, Safari, Edge, Opera  */
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /*  Firefox  */
  [type='number'] {
    -moz-appearance: textfield;
  }
  .ant-modal-content {
    background-color: rgba(44, 40, 96, 255);
    width: 100%;
    margin-top: 100px;
  }
  .ant-modal-header > .ant-modal-title {
    background-color: rgba(44, 40, 96, 255);
    color: ${(props) => props.theme.colors.white};
  }
  .modal-container {
    display: flex;
    flex-direction: column;
    gap: 40px;
    width: 80%;
    margin: auto;
    align-items: center;

    margin-bottom: 40px;
  }
  .ant-modal-footer > .ant-btn.ant-btn-primary {
    background-color: ${(props) => props.theme.colors.blue.light} !important;
    color: rgba(44, 40, 96, 255);
  }
  .otp-fields {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 40px;
    width: 80%;
    margin: auto;
    input {
      background: rgba(44, 40, 96, 255);
      border: none;
      border-bottom: 1px solid #8a8aa0;
      border-radius: 0;
      color: ${(props) => props.theme.colors.blue.light};
      text-align: center;
      font-size: 20px;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
  .btns {
    display: flex;
    justify-content: space-between;
    gap: 40px;
    Button {
      background: linear-gradient(to right, #7000ff, #1154ff 60%) !important;
      font-family: 'Urbanist';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 22px;
      /* or 96% */

      text-align: center;

      color: ${(props) => props.theme.colors.white};
      border: none;
      border-radius: 30px;
      min-width: 100px;
    }
  }
  .timer-container {
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }
  .timer-disabled {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #79869f;
  }
  .timer {
    color: ${(props) => props.theme.colors.blue.light};
  }
`
export const StyledOtpInput = styled(OTPInput)`
  input {
    background: ${(props) => props.theme.colors.blue.dark};
    border: none;
    border-bottom: 1px solid #8a8aa0;
    border-radius: 0;
  }
`
