import React from 'react'

function Facebook() {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.0558 35.1164C11.8143 34.6274 8.77503 33.2693 6.27599 31.193C3.77695 29.1167 1.91606 26.4036 0.900222 23.3554C-0.115613 20.3071 -0.246577 17.0432 0.521893 13.9266C1.29036 10.81 2.92815 7.96277 5.25315 5.70153C7.31738 3.68937 9.85502 2.20278 12.6437 1.37206C15.4324 0.541342 18.3869 0.391842 21.2484 0.936665C24.11 1.48149 26.7911 2.704 29.0569 4.49707C31.3226 6.29014 33.1039 8.59902 34.2445 11.2214C34.6996 12.2711 35.047 13.3623 35.2816 14.479C35.741 16.6766 35.7633 18.94 35.3473 21.1458C34.9646 23.165 34.2153 25.1009 33.1348 26.862C31.7958 29.0511 29.9804 30.9253 27.8174 32.3518C25.6544 33.7783 23.1968 34.7222 20.6188 35.1164V22.9912H24.7673L25.5564 17.9753H20.6188V14.5729C20.6275 14.2469 20.686 13.924 20.7923 13.6148C20.8955 13.3111 21.0624 13.0317 21.2824 12.7946C21.577 12.5175 21.928 12.3041 22.3126 12.1684C22.6972 12.0327 23.1067 11.9777 23.5145 12.007H25.7593V7.74045C25.7593 7.74045 25.2359 7.65422 24.4967 7.57086C23.5929 7.46566 22.6838 7.40905 21.7736 7.40127C17.5584 7.40127 15.0489 9.92501 15.0489 14.1523V17.9753H10.5348V22.9912H15.0558V35.1164Z"
        fill="#7000FF"
      />
    </svg>
  )
}

export default Facebook
