import { createApi } from '@reduxjs/toolkit/query/react'
import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query'
import config from '../configuration/Config'

export const publicWethioBaseQuery = fetchBaseQuery({
  baseUrl: config.WETHIO_URL,
  //   prepareHeaders: (headers, { getState }) => {
  //     const token = getState().commonRed.token;
  //     return headers;
  //   },
})

// Define a service using a base URL and expected endpoints
export const publicWethioApi = createApi({
  reducerPath: 'publicWethioApi',
  baseQuery: publicWethioBaseQuery,
  endpoints: (builder) => ({
    getTransactions: builder.query({
      query: (data) => ({
        url: `/${data?.address}`,
        params: { page: data?.page, limit: '6' },
      }),
    }),
  }),
})

export const { useLazyGetTransactionsQuery } = publicWethioApi
