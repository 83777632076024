const ROUTES = {
  HOME: '/',
  HOW_IT_WORKS: '/how-it-works',
  SUBMIT_IDEAS: '/submit-ideas',
  ABOUT_US: '/about-us',
  LOGIN: '/login',
  ICO: '/ico',
  APPLY_FORM: '/apply-form',
  MESSAGE_FORM: '/message-form',
  BUYNOW: '/buy-now',
  PROJECTS: '/projects',
  APPLICATIONS: '/applications',
  SETTINGS: '/settings',
  SIGNUP: '/signup',
  APPLICATIONFORM: '/applicationform',
  VERIFYEMAIL: '/verify-email',
  FORGOTPASSWORD: '/forgot-password',
  OTP: '/otp',
  // CREATEPASS: "/create-password",
  CONFIRMPASS: '/confirm-password',
  EDITAPPLICATION: '/edit-application',
  CREATEPASS: '/reset-password',
  PASSWORDCHANGED: '/password-changed',
  VALIDATEPROJECT: '/settings/validate-project',
  VOTING: '/settings/voting',
  MYINVESTORS: '/settings/my-investors',
  VOTE: '/settings/vote',
  REVIEWS: '/settings/reviews',
  MYINVESTMENT: '/settings/my-investment',
  RATINGS: '/ratings',
  EDITRATINGS: '/edit-ratings',
  COMPLETERATINGS: '/complete-ratings',
  PROFILE: '/settings/profile',
  AUDITORAPPLICATION: '/auditor-application',
  CREATEPROPOSAL: '/settings/create-proposal',
  EDITAUDITORAPPLICATION: '/auditor-application',
  WITHDRAWLHISTORY: '/settings/withdrawl-history',
  EXPERTPROFILE: '/expert-profile',
}

export default ROUTES
