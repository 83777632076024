import React from 'react'

function Google() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_355_759)">
        <path
          d="M8.37336 13.2578L2.42375 9.82275C1.27413 12.1459 0.623115 14.766 0.623047 17.5515C0.623047 20.2985 1.28109 22.9231 2.44838 25.2668L8.37295 21.8463C7.78859 20.5305 7.45749 19.0792 7.45756 17.5515C7.45762 16.0239 7.78893 14.5731 8.37336 13.2578Z"
          fill="white"
        />
        <path
          d="M29.5459 4.36789C26.4764 1.69792 22.4654 0.0825195 18.0769 0.0854532C12.0729 0.0894786 6.71401 3.15462 3.56543 7.85125L9.51989 11.2892C11.4562 8.64786 14.5714 6.91989 18.0892 6.91996C20.6536 6.92003 23.0044 7.83672 24.8405 9.35571C25.2899 9.72748 25.947 9.69343 26.3594 9.281L29.6023 6.03814C30.0699 5.57051 30.0448 4.80181 29.5459 4.36789Z"
          fill="white"
        />
        <path
          d="M35.4356 15.5303C35.3675 14.9521 34.8814 14.5144 34.2994 14.5144L19.9877 14.5137C19.3587 14.5137 18.8486 15.0237 18.8486 15.6528V20.2053C18.8486 20.8344 19.3586 21.3444 19.9877 21.3444H28.0076C27.2689 23.267 25.9859 24.9197 24.3524 26.1212L27.7588 32.0212C31.1876 29.6977 33.7946 26.1847 34.9428 22.0376C35.5671 19.7828 35.6795 17.6024 35.4356 15.5303Z"
          fill="white"
        />
        <path
          d="M22.3823 27.2658C21.0686 27.8511 19.6184 28.1831 18.089 28.1832C14.5673 28.1833 11.4539 26.4552 9.51892 23.8149L3.58984 27.2381C6.71202 31.8886 12.0109 35.0176 18.0889 35.0176C20.8365 35.0176 23.4517 34.346 25.7909 33.1698L22.3823 27.2658Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_355_759">
          <rect
            width="34.9319"
            height="34.9319"
            fill="white"
            transform="translate(0.623047 0.0854492)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Google
