import { Select } from 'antd'
import styled from 'styled-components'
import { deviceQuery } from '../../Constants/MediaSizes'

const CountrySelectContainer = styled(Select)`
  text-align: left;
  background: ${(props) => props.theme.colors.blue.dark};
  /* height: 55.87px; */
  color: ${(props) => props.theme.colors.white};
  border-color: #343444;
  height: 40px;
  .ant-select-selector > .ant-select-selection-search > input {
  }

  /* width: 25%; */
  & .ant-select-selector > .ant-select-selection-search > input {
    color: #8a8aa0 !important;
  }

  & .ant-select-selector > .ant-select-selection-placeholder {
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #8a8aa0;
  }
  & > .ant-select-selector {
    border-color: #343444 !important;
    background: ${(props) => props.theme.colors.blue.dark} !important;
  }

  .ant-select-selector {
    max-width: 130px !important;
    @media (${deviceQuery.mobileSM}) {
      max-width: 100% !important;
    }
  }
`

export default CountrySelectContainer
