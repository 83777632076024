const wethioChain = {
  id: 78,
  name: 'Wethio',
  // network: 'wethio',
  nativeCurrency: {
    decimals: 18,
    name: 'ZYN',
    symbol: 'ZYN',
  },
  rpcUrls: {
    public: 'https://rpc.wethio.io',
    default: 'https://rpc.wethio.io',
  },
  blockExplorers: {
    etherscan: { name: 'Wethio', url: 'https://explorer.wethio.io' },
    default: { name: 'Wethio', url: 'https://explorer.wethio.io' },
  },
  testnet: false,
}

export default wethioChain

// const wethioChain = {
//   id: 79,
//   name: 'Wethio Testnet',
//   // network: 'wethio',
//   nativeCurrency: {
//     decimals: 18,
//     name: 'ZYN',
//     symbol: 'ZYN',
//   },
//   rpcUrls: {
//     public: 'https://rpc.testnet.wethio.io',
//     default: 'https://rpc.testnet.wethio.io',
//   },
//   blockExplorers: {
//     etherscan: { name: 'Wethio', url: 'https://explorer.testnet.io' },
//     default: { name: 'Wethio', url: 'https://explorer.testnet.io' },
//   },
//   testnet: true,
// }

// export default wethioChain
