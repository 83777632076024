import React from 'react'
import { useGoogleLogin } from '@react-oauth/google'
import { useNavigate } from 'react-router'
import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'
import { StyledButton } from '../../GlobalStyles'
import GoogleContainer from './GoogleContainer.styles'
import { setIsLogin, setToken } from '../../Services/Auth'
import { useGoogleLoginMutation } from '../../Services/PublicApis'
import ROUTES from '../../Constants/Routes'
import Google from '../../Assets/SocialLogos/Google'
import { ROLE } from '../../Constants/Enums'
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from '../../Utils/localStorage'

function GoogleLoginn() {
  const role = getLocalStorageItem('role') ?? ROLE.CLIENT
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [googleLogin] = useGoogleLoginMutation()
  const loginGoogle = useGoogleLogin({
    flow: 'auth-code',
    onSuccess: async (tokenResponse) => {
      const data = {
        code: tokenResponse?.code,
        role,
      }
      try {
        const response = await googleLogin(data).unwrap()
        setLocalStorageItem('role', role)
        setLocalStorageItem('accessToken', response?.accessToken)
        dispatch(setToken(response?.accessToken))
        dispatch(setIsLogin(true))
        toast.success(response?.message)
        navigate(ROUTES.HOME)
      } catch (error) {
        toast.error(error?.data?.message)
      }
    },
  })

  return (
    <GoogleContainer>
      <StyledButton className="google-btn" onClick={() => loginGoogle()}>
        <Google />
        Google
      </StyledButton>
    </GoogleContainer>
  )
}

export default GoogleLoginn
