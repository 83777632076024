import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import ROUTES from '../Constants/Routes'
import { ROLE } from '../Constants/Enums'
import { getLocalStorageItem } from '../Utils/localStorage'

function ClientRoute() {
  const role = getLocalStorageItem('role') === ROLE.CLIENT
  return role ? <Outlet /> : <Navigate to={ROUTES.HOME} />
}

export default ClientRoute
