import { DownOutlined } from '@ant-design/icons'
import { Button, Dropdown, Space } from 'antd'
import React from 'react'
import { useNavigate } from 'react-router'
import ROUTES from '../../Constants/Routes'
import StyledConnection from './StyledConnection.styles'
import { setLocalStorageItem } from '../../Utils/localStorage'

function ConnectionButton({ drawer, onClose }) {
  const navigate = useNavigate()
  const handleMenuClick = (e) => {
    if (e?.key === '1') {
      setLocalStorageItem('role', 'client')
    } else if (e?.key === '2') {
      setLocalStorageItem('role', 'expert')
    }
    navigate(ROUTES.LOGIN)
    if (drawer) {
      onClose()
    }
  }
  const items = [
    {
      label: 'Client',
      key: '1',
    },
    {
      label: 'Expert',
      key: '2',
    },
  ]
  const menuProps = {
    items,
    onClick: handleMenuClick,
  }
  return (
    <StyledConnection>
      <Dropdown
        getPopupContainer={(triggerNode) => triggerNode}
        menu={menuProps}
        trigger={['click']}
      >
        <Button className="right-button">
          <Space>
            Connection
            <DownOutlined />
          </Space>
        </Button>
      </Dropdown>
    </StyledConnection>
  )
}

export default ConnectionButton
