import React from 'react'

function World() {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.6448 35.1161C27.3897 35.1161 35.2895 27.3945 35.2895 17.8696C35.2895 8.34457 27.3897 0.623047 17.6448 0.623047C7.89983 0.623047 0 8.34457 0 17.8696C0 27.3945 7.89983 35.1161 17.6448 35.1161Z"
        stroke="#7000FF"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M17.6447 35.1161C22.5171 35.1161 26.467 27.3945 26.467 17.8696C26.467 8.34457 22.5171 0.623047 17.6447 0.623047C12.7722 0.623047 8.82227 8.34457 8.82227 17.8696C8.82227 27.3945 12.7722 35.1161 17.6447 35.1161Z"
        stroke="#7000FF"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M4.28662 28.6858C7.44111 26.7877 12.2532 25.5767 17.6447 25.5767C22.9617 25.5767 27.715 26.7552 30.8704 28.6073"
        stroke="#7000FF"
        strokeMiterlimit="10"
      />
      <path
        d="M31.0028 7.0542C27.8483 8.95132 23.0361 10.1634 17.6447 10.1634C12.3277 10.1634 7.57442 8.98485 4.41895 7.13277"
        stroke="#7000FF"
        strokeMiterlimit="10"
      />
      <path d="M0 17.8696H35.2895" stroke="#7000FF" strokeMiterlimit="10" />
      <path
        d="M17.6445 0.623047V35.1161"
        stroke="#7000FF"
        strokeMiterlimit="10"
      />
    </svg>
  )
}

export default World
