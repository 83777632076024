import { toast } from 'react-toastify'
import Web3 from 'web3'
import wethioChain from '../configuration/wethioChain'
import { getLocalStorageItem, setLocalStorageItem } from './localStorage'

export const getAccount = async (web3) => {
  try {
    const account = await web3.eth.getAccounts()
    return account
  } catch (error) {
    return ''
  }
}

export const getChainId = async () => {
  if (window?.ethereum) {
    await window.web3.currentProvider.enable()
    const web3 = new Web3(window.web3.currentProvider)
    return web3.eth.net.getId()
  }
  return undefined
}

export const changeNetwork = async () => {
  const chainId = wethioChain.id // Polygon Mainnet
  await window.web3.currentProvider.enable()
  const web3 = new Web3(window.web3.currentProvider)
  if (window.ethereum.networkVersion !== chainId) {
    try {
      const network = await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: web3.utils.toHex(chainId) }],
      })
      return network
    } catch (err) {
      // This error code indicates that the chain has not been added to MetaMask
      if (err.code === 4902) {
        await window.ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainName: 'Wethio',
              chainId: web3.utils.toHex(chainId),
              nativeCurrency: { name: 'zyn', decimals: 18, symbol: 'zyn' },
              rpcUrls: ['https://rpc.wethio.io'],
            },
          ],
        })
      }
      if (err.code === 4001) {
        toast(err.message)
        return err.code
      }
    }
  }
  return undefined
}

export const walletConnectUser = async (walletType, web3, message) => {
  if (walletType === 'METAMASK') {
    const network = await changeNetwork()
    if (network !== 4001) {
      const walletAddress = await web3.eth.requestAccounts()
      const signature = await web3.eth.personal.sign(
        web3?.utils?.utf8ToHex(message),
        walletAddress[0]
      )
      setLocalStorageItem('userwalletAddress', walletAddress[0])
      setLocalStorageItem('signature', signature)

      return { walletAddress, signature }
    }

    toast('Please change you network ')
  } else if (walletType === 'walletconnect') {
    const walletSession = JSON.parse(getLocalStorageItem('walletconnect'))
    // let wallet = JSON.parse(localStorage.getItem('userwalletAddress'))
    const wallet = walletSession?.accounts[0]
    return wallet
  }
  return ''
}
