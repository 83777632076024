/* eslint-disable prefer-promise-reject-errors */
import { Button, Form } from 'antd'
import { useForm } from 'antd/es/form/Form'
import React, { useState } from 'react'
// import { useNavigate } from 'react-router'
import { toast } from 'react-toastify'
import dayjs from 'dayjs'
import Forgot from '../../Assets/Forgot'
import CountrySelect from '../../Components/CountrySelect'
// import Navbar from '../../Components/Navbar/Navbar'
// import ROUTES from '../../Constants/Routes'
import {
  useSendOtpForEmailMutation,
  useSendOtpForPhoneMutation,
  useVerifyOtpMutation,
} from '../../Services/PublicApis'

import {
  StyledForgotForm,
  StyledButton,
  StyledInput,
} from './ForgotForm.styles'
import { handlePaste } from '../../Utils/Common'
import VerifyNumModal from '../../Components/VerifyNumModal'
import CreatePass from '../CreatePass/CreatePass'

function ForgotForm() {
  const restrictedKeys = ['+', '-', 'e']
  // const navigate = useNavigate()
  const [sendOtpForEmail, { isLoading: sendEmailLoading }] =
    useSendOtpForEmailMutation()
  const [sendOtpForPhone, { isLoading: sendMobileLoading }] =
    useSendOtpForPhoneMutation()
  const [verifyOtp, { isLoading: verifyLoading }] = useVerifyOtpMutation()
  const [isEmailField, setIsEmailField] = useState(true)
  const [forgotForm] = useForm()
  const [opens, setOpens] = useState(false)
  const [verifyData, setVerifyData] = useState()
  const [token, setToken] = useState('')
  const [changePassword, setChangePassword] = useState(false)
  const [deadline, setDeadline] = useState(0)

  const handleForgot = async (values) => {
    try {
      if (isEmailField) {
        const response = await sendOtpForEmail(values).unwrap()
        const time = dayjs(response?.sendAt).add(180, 'seconds')
        setDeadline(time)
        toast.success(response?.message)
        const data = forgotForm.getFieldsValue()
        // navigate(ROUTES.OTP, {
        //   state: {
        //     ...data,
        //     userId: response?.userId,
        //   },
        // })
        setVerifyData({
          ...data,
          userId: response?.userId,
        })
      } else {
        let code = ''
        if (values.countryCode[0] === '+') {
          code = values.countryCode
        } else {
          code = `+${values.countryCode}`
        }
        const data = {
          ...values,
          countryCode: code,
          phoneNumber: Number(values.phoneNumber),
        }
        const response = await sendOtpForPhone(data).unwrap()
        const time = dayjs(response?.sendAt).add(180, 'seconds')
        setDeadline(time)
        toast.success(response?.message)
        // const otp = forgotForm.getFieldsValue()
        // navigate(ROUTES.OTP, {
        //   state: {
        //     ...otp,
        //     userId: response?.userId,
        //   },
        // })
        setVerifyData({
          // ...otp,
          ...data,
          userId: response?.userId,
        })
      }
      setOpens(true)
    } catch (err) {
      toast.error(err?.data?.message)
    }
  }
  const handlePasteValue = (event) => {
    const sanitizedValue = handlePaste(event)

    forgotForm.setFields([
      {
        name: 'phoneNumber',
        value: sanitizedValue,
        errors: [''],
      },
    ])
    // signUpForm.setFieldValue('phoneNumber', sanitizedValue)
    const div = document.getElementById('phoneNumber')
    div.classList.remove('ant-input-status-error')

    event.preventDefault()
  }
  // console.log(token, changePassword, 'token')
  const handleVerifyOtp = async (otp, setOtp) => {
    try {
      if (otp) {
        const data = {
          otp: Number(otp),
          userId: verifyData?.userId,
        }
        const response = await verifyOtp(data).unwrap()
        toast.success(response?.message)
        // navigate(ROUTES.CREATEPASS, {
        //   state: {
        //     token: response?.token,
        //   },
        //   replace: true,
        // })
        setToken(response?.token)
        setChangePassword(true)
        setOtp('')
        setOpens(false)
      } else {
        toast.warn('Please enter valid OTP')
      }
    } catch (err) {
      toast.error(err?.data?.message)
    }
  }

  const handleCancels = () => {
    // setSeconds(59)
    // setMinutes(2)
    setOpens(false)
  }

  const resendPasswordFn = async () => {
    try {
      if (isEmailField) {
        const response = await sendOtpForEmail({
          email: verifyData?.email,
        }).unwrap()
        const time = dayjs(response?.sendAt).add(180, 'seconds')
        setDeadline(time)
        toast.success(response?.message)
      } else {
        const data = {
          countryCode: verifyData?.countryCode,
          phoneNumber: Number(verifyData?.phoneNumber),
        }
        const response = await sendOtpForPhone(data).unwrap()
        const time = dayjs(response?.sendAt).add(180, 'seconds')
        setDeadline(time)
        toast.success(response?.message)
      }
      // setSeconds(59)
      // setMinutes(2)
    } catch (err) {
      toast.error(err?.data?.message)
    }
  }

  const handleCountrySelect = () => {
    forgotForm.setFieldValue('phoneNumber', null)
    // setlastMobileNumber(null)
    // setPhoneField(null)
  }

  return (
    <div>
      {changePassword ? (
        <CreatePass token={token} />
      ) : (
        <StyledForgotForm>
          {/* <Navbar /> */}
          <div className="forgot-form">
            <h4>Forgot Password</h4>
            <p>Remember & input your email or phone number below.</p>
            <div className="forgot-img">
              <Forgot />
            </div>

            <Form
              className="form"
              name="basic"
              onFinish={handleForgot}
              initialValues={{
                remember: true,
              }}
              form={forgotForm}
              autoComplete="off"
            >
              {isEmailField ? (
                <Form.Item
                  name="email"
                  style={{ width: '100%', marginBottom: '0px' }}
                  rules={[
                    {
                      required: true,
                      message: 'Please input email',
                    },
                    // {
                    //   type: 'email',
                    //   message: 'Must be valid Email',
                    // },
                    () => ({
                      validator(_, value) {
                        const decimalPattern =
                          /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i

                        if (!value || decimalPattern.test(value)) {
                          return Promise.resolve()
                        }

                        return Promise.reject('Must be valid Email')
                      },
                    }),
                  ]}
                >
                  <StyledInput placeholder="Please enter email" />
                </Form.Item>
              ) : (
                <div className="phone-number-input">
                  <CountrySelect
                    marginBottom="0px"
                    size="large"
                    // labels={en}
                    isRequired
                    handleCountrySelect={handleCountrySelect}
                    className="country-code"
                  />
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: 'Please input phone number',
                      },
                      // {
                      //   message: "Required",
                      //   validator: (_, value) => {
                      //     if (value?.length > 0) {
                      //       return Promise.resolve();
                      //     } else {
                      //       return Promise.reject("Some message here");
                      //     }
                      //   },
                      // },
                      {
                        min: 6,
                        message: 'Minimum 6 digits required',
                      },
                      {
                        max: 15,
                        message: "Phone Number Can't be more than 15 digits",
                      },
                      // {
                      //   message: "Please enter valid phone number",
                      //   validator: (_, value) => {
                      //     if (/\d+/g.test(value)) {
                      //       return Promise.resolve();
                      //     } else {
                      //       return Promise.reject("Some message here");
                      //     }
                      //   },
                      // },
                    ]}
                    name="phoneNumber"
                    className="phone-input"
                  >
                    <StyledInput
                      size="large"
                      id="phoneNumber"
                      placeholder="Enter number"
                      onPaste={handlePasteValue}
                      type="number"
                      onKeyDown={(e) => {
                        if (restrictedKeys.includes(e?.key)) {
                          e.preventDefault()
                        }
                      }}
                    />
                  </Form.Item>
                </div>
              )}
              <Button
                onClick={() => setIsEmailField((prev) => !prev)}
                type="link"
              >
                {isEmailField ? 'Use phone instead' : 'Use email instead'}
              </Button>
              {/* <Form.Item
            name="role"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <StyledSelect
              size="large"
              placeholder="Are you a project owner or an applicant"
              // onChange={onValueChange}
              allowClear
            >
              <Option value="projectOwner">Project Owner</Option>
              <Option value="auditor">Auditor</Option>
              <Option value="investor">Investor</Option>
            </StyledSelect>
          </Form.Item> */}
              <StyledButton
                type="primary"
                htmlType="submit"
                className="login-btn"
                size="large"
                loading={sendEmailLoading || sendMobileLoading}
              >
                Reset Password
              </StyledButton>
            </Form>
          </div>
          <VerifyNumModal
            opens={opens}
            handleCancel={handleCancels}
            handleOks={handleVerifyOtp}
            resendOTP={resendPasswordFn}
            verifyEmailLoading={verifyLoading}
            sendEmailLoading={sendEmailLoading}
            verifyMobileLoading={verifyLoading}
            sendMobileLoading={sendMobileLoading}
            deadline={deadline}
          />
        </StyledForgotForm>
      )}
    </div>
  )
}

export default ForgotForm
