import { Input, InputNumber, Select, Button, Statistic } from 'antd'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import TextArea from 'antd/es/input/TextArea'
import { deviceQuery } from './Constants/MediaSizes'

const { Countdown } = Statistic

export const StyledNavlink = styled(NavLink)`
  color: ${(props) => props.theme.colors.white};
  text-decoration: none;
  &.active {
    color: #7000ff !important;
  }
`

export const StyledLoginInput = styled(Input)`
  background: ${(props) => props.theme.colors.blue.dark};
  /* height: 55.87px; */
  color: white;
  border-color: #343444;
  height: 40px;

  &::placeholder {
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #8a8aa0;
  }

  &:disabled {
    color: white;
    outline: none;
    border-color: #343444;
  }

  /*  Chrome, Safari, Edge, Opera  */
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /*  Firefox  */
  [type='number'] {
    -moz-appearance: textfield;
  }

  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus,
  :-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px ${(props) => props.theme.colors.blue.dark}
      inset !important;
    -webkit-text-fill-color: ${(props) => props.theme.colors.white} !important;
  }

  &:hover,
  &:focus {
    border-color: #343444 !important;
    box-shadow: none;
  }
`
export const StyledInputNumber = styled(InputNumber)`
  background: ${(props) => props.theme.colors.blue.dark};
  /* height: 55.87px; */
  color: white;
  border-color: #343444;
  height: 40px;
  &::placeholder {
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #8a8aa0;
  }
  /*  Chrome, Safari, Edge, Opera  */
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /*  Firefox  */
  [type='number'] {
    -moz-appearance: textfield;
  }
`
export const StyledTextArea = styled(TextArea)`
  background: ${(props) => props.theme.colors.blue.dark};
  border-color: #343444;
  min-height: 150px !important;
  color: white;
  &::placeholder {
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #8a8aa0;
  }
`

export const StyledLoginPassword = styled(Input.Password)`
  background: ${(props) => props.theme.colors.blue.dark};
  border-color: #343444;
  color: white;
  height: 40px;
  input {
    color: white !important;
    background: ${(props) => props.theme.colors.blue.dark};
  }
  & input::placeholder {
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;

    color: #8a8aa0;
  }
  & > .ant-input-suffix > .anticon {
    color: #8a8aa0;
  }
  & > .ant-input-suffix > .anticon:hover {
    color: #8a8aa0;
  }
  & > input:-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus,
  :-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px ${(props) => props.theme.colors.blue.dark}
      inset !important;
    -webkit-text-fill-color: ${(props) => props.theme.colors.white} !important;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-text-fill-color: transparent;
    -webkit-box-shadow: 0 0 0px 1000px #000 inset;
    transition: background-color 5000s ease-in-out 0s;
  }
`

export const StyledButton = styled(Button)`
  width: 100%;
  background: ${(prop) => prop.theme.colors.blue.dark};
  border-color: ${(prop) => prop.theme.colors.white};
  border-radius: 56px;
  font-family: 'Urbanist';
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 22px;
`

export const StyledSelect = styled(Select)`
  height: 40px;
  /* & > .ant-select-selector { */
  width: 100%;
  & > .ant-select-selector > .ant-select-selection-item {
    color: white;
  }
  & > .ant-select-selector {
    background: ${(props) => props.theme.colors.blue.dark}!important;
    /* height: 55.87px; */
    color: white;
    text-align: left;
    border-color: #343444 !important;
  }
  /* } */
  & > .ant-select-selector > .ant-select-selection-placeholder {
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 400 !important;
    font-size: 14px;
    line-height: 22px;
    text-align: left;
    padding-top: 3px;
    color: ${(props) => props.theme.colors.placeholder}!important;
  }
  & > .ant-select-arrow {
    color: white;
    top: 33%;
  }

  &:focus {
    outline: none;
  }
`

export const StyledCustomTimer = styled(Countdown)`
  & > .ant-statistic-content {
    color: ${(props) => props.theme.colors.blue.grad1} !important;
    text-align: right;
    font-size: 14px;
    @media (${deviceQuery.tabletMS}) {
      text-align: center;
    }
  }
`
