import React from 'react'

function NavbarIcon() {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 66 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="66" height="66" rx="10" fill="#7000FF" />
      <path
        d="M21.0438 41.8978L21.0184 41.9232C20.6757 41.1744 20.4599 40.324 20.3711 39.3848C20.4599 40.3113 20.7011 41.149 21.0438 41.8978Z"
        fill="#292D32"
      />
      <path
        d="M29.1926 30.9439C30.861 30.9439 32.2134 29.5914 32.2134 27.9231C32.2134 26.2548 30.861 24.9023 29.1926 24.9023C27.5243 24.9023 26.1719 26.2548 26.1719 27.9231C26.1719 29.5914 27.5243 30.9439 29.1926 30.9439Z"
        fill="#292D32"
      />
      <path
        d="M38.319 20.3086H27.6828C23.0628 20.3086 20.3086 23.0628 20.3086 27.6828V38.319C20.3086 39.7024 20.5497 40.9082 21.0194 41.9236C22.1109 44.3351 24.4463 45.6932 27.6828 45.6932H38.319C42.939 45.6932 45.6932 42.939 45.6932 38.319V35.4124V27.6828C45.6932 23.0628 42.939 20.3086 38.319 20.3086ZM43.6244 33.6355C42.6344 32.7851 41.0351 32.7851 40.0451 33.6355L34.7651 38.1667C33.7751 39.0171 32.1759 39.0171 31.1859 38.1667L30.7544 37.8113C29.8532 37.0244 28.419 36.9482 27.4036 37.6336L22.6567 40.8194C22.3774 40.1086 22.2124 39.2836 22.2124 38.319V27.6828C22.2124 24.1036 24.1036 22.2124 27.6828 22.2124H38.319C41.8982 22.2124 43.7894 24.1036 43.7894 27.6828V33.7751L43.6244 33.6355Z"
        fill="#292D32"
      />
    </svg>
  )
}

export default NavbarIcon
