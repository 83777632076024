const theme = {
  dark: {
    colors: {
      placeholder: '#8A8AA0',
      white: '#ffffff',
      black: '#000000',
      blue: {
        extraDark: '#130f40',
        dark: '#130f40',
        lightDark: '#090552',
        semiDark: '#00113E',
        light: '#7000FF',
        semiLight: '#141931',
        grad1: '#7000FF',
        grad2: '#1154FF',
      },
      gradient: '#C4C4C4',
      grey: '#A09FAF',
      violet: '#6E00F7',
      yellow: {
        dark: '#dc9f1d',
        light: '#e2eb38',
      },
      pink: '#ff8b8b',
    },
  },
  light: {
    colors: {
      placeholder: '#8A8AA0',
      white: '#ffffff',
      black: '#000000',
      blue: {
        extraDark: '#130f40',
        dark: '#130f40',
        lightDark: '#090552',
        semiDark: '#00113E',
        light: '#7000FF',
        semiLight: '#141931',
        grad1: '#7000FF',
        grad2: '#1154FF',
      },
      gradient: '#C4C4C4',
      grey: '#A09FAF',
      violet: '#6E00F7',
      yellow: {
        dark: '#dc9f1d',
        light: '#e2eb38',
      },
      pink: '#ff8b8b',
    },
  },
}

export default theme
