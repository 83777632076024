import React from 'react'
import { useNavigate } from 'react-router'
import { Button } from 'antd'
import Logo from '../../Assets/Logo'
import ROUTES from '../../Constants/Routes'
import { StyledNavlink } from '../../GlobalStyles'
import StyledCompleteRatings from './CompleteRatings.styles'

function CompleteRatings() {
  const navigate = useNavigate()
  return (
    <StyledCompleteRatings>
      <div className="nav">
        <Logo
          onClick={() => navigate(ROUTES.HOME)}
          fill="#7000FF"
          className="logo"
        />
        <div className="login-head">
          <StyledNavlink to={ROUTES.APPLICATIONS}>Ratings</StyledNavlink>
          <StyledNavlink to={ROUTES.PROFILE}>Settings</StyledNavlink>
          <StyledNavlink to={ROUTES.LOGIN}>Logout</StyledNavlink>
        </div>
      </div>
      <div className="main-container">
        <div className="img">
          <img src="/assets/CompleteRate.png" alt="" />
        </div>
        <div className="text">
          <h2>Thank you for using MisterZ !</h2>
          <p>
            Kindly be informed that the audit for [project name] has been
            completed and is now available for review.
          </p>
          <p>
            Thank you for the opportunity to conduct this audit, and we look
            forward to working with you in the future
          </p>
        </div>
      </div>
      <div className="btns">
        <Button>View Reviews</Button>
        <Button>Edit Reviews</Button>
      </div>
    </StyledCompleteRatings>
  )
}

export default CompleteRatings
