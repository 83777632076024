/* eslint-disable react/jsx-wrap-multilines */
import React, { Suspense, lazy } from 'react'
import { Route, Routes } from 'react-router'
import { Spin } from 'antd'
import ROUTES from '../Constants/Routes'
import LowerFooter from '../Components/Footer/LowerFooter'
import PublicRoutes from '../Routes/PublicRoutes'
// import ApplyForm from '../Screens/ApplyForm/ApplyForm'
import MessageForm from '../Screens/MessageForm/MessageForm'
import ForgotForm from '../Screens/ForgotForm/ForgotForm'
// import Otp from '../Screens/Otp/Otp'
import PrivateRoute from '../Routes/PrivateRoutes'
import CompleteRatings from '../Screens/CompleteRatings/CompleteRatings'
import NotFound from '../Screens/NotFound'
import WithdrawlHistory from '../Screens/Settings/Components/WithdrawalHistory/WithdrawalHistory'
import ClientRoute from '../Routes/ClientRoute'
import ExpertRoute from '../Routes/ExpertRoute'

// const CreatePass = lazy(() => import('../Screens/CreatePass/CreatePass'))
const ConfirmPass = lazy(() => import('../Screens/ConfirmPass'))
const ValidateProject = lazy(() =>
  import('../Screens/Settings/Components/ValidateProject')
)
const Voting = lazy(() =>
  import('../Screens/Settings/Components/Voting/Voting')
)
const Ratings = lazy(() => import('../Screens/Ratings'))
const Vote = lazy(() => import('../Screens/Settings/Components/Vote/Vote'))
const MyInvestment = lazy(() =>
  import('../Screens/Settings/Components/MyInvestment')
)
const Reviews = lazy(() => import('../Screens/Settings/Components/Reviews'))
const Profile = lazy(() => import('../Screens/Settings/Components/Profile'))
const MyInvestors = lazy(() =>
  import('../Screens/Settings/Components/MyInvestors')
)
const CreateProposal = lazy(() =>
  import('../Screens/Settings/Components/CreateProposal/CreateProposal')
)
const Login = lazy(() => import('../Screens/Login'))
const BuyNow = lazy(() => import('../Screens/BuyNow'))
const Home = lazy(() => import('../Screens/Home/Home'))
const HomeMain = lazy(() => import('../Screens/Home'))
const ICOMain = lazy(() => import('../Screens/ICO'))
const Project = lazy(() => import('../Screens/Projects'))
const ProjectMain = lazy(() => import('../Screens/Projects'))
const Applications = lazy(() => import('../Screens/Applications'))
const Settings = lazy(() => import('../Screens/Settings'))
const SignUp = lazy(() => import('../Screens/SignUp'))
const ApplicationForm = lazy(() =>
  import('../Screens/ApplicationForm/ApplicationForm')
)
const EditApplicationForm = lazy(() => import('../Screens/EditApplicationForm'))
const EmailVerification = lazy(() => import('../Screens/VerifyEmail'))
const AuditorApplication = lazy(() =>
  import('../Screens/AuditorApplication/AuditorApplication')
)
const ExpertProfile = lazy(() => import('../Screens/ExpertProfile'))
const AboutUs = lazy(() => import('../Screens/AboutUs'))
const HowItWorks = lazy(() => import('../Screens/HowItWorks'))

function AppRoutes() {
  return (
    <Suspense
      fallback={
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
          }}
        >
          <Spin size="large" />
        </div>
      }
    >
      <Routes>
        <Route path="*" element={<NotFound />} />
        <Route element={<PublicRoutes />}>
          <Route path={ROUTES.LOGIN} element={<Login />} />
          <Route path={ROUTES.SIGNUP} element={<SignUp />} />
          <Route path={ROUTES.BUYNOW} element={<BuyNow />} />
          <Route path={ROUTES.FORGOTPASSWORD} element={<ForgotForm />} />
          <Route path={ROUTES.VERIFYEMAIL} element={<EmailVerification />} />
          {/* <Route path={ROUTES.OTP} element={<Otp />} />
          <Route path={ROUTES.CREATEPASS} element={<CreatePass />} /> */}
          <Route path={ROUTES.PASSWORDCHANGED} element={<ConfirmPass />} />
        </Route>
        <Route path={`${ROUTES.ICO}/:id`} element={<ICOMain />} />
        {/* <Route path={ROUTES.APPLY_FORM} element={<ApplyForm />} /> */}
        <Route path={ROUTES.MESSAGE_FORM} element={<MessageForm />} />
        {/* </Route> */}
        <Route path={ROUTES.HOME} element={<Home />}>
          <Route index element={<HomeMain />} />
        </Route>
        <Route path={ROUTES.PROJECTS} element={<Project />}>
          <Route index element={<ProjectMain />} />
        </Route>
        <Route
          element={
            <PrivateRoute>
              <ClientRoute />
            </PrivateRoute>
          }
        >
          <Route path={ROUTES.APPLICATIONS} element={<Applications />} />
          <Route path={ROUTES.APPLICATIONFORM} element={<ApplicationForm />} />
          <Route
            path={`${ROUTES.EDITAPPLICATION}/:id`}
            element={<EditApplicationForm />}
          />
        </Route>
        <Route
          element={
            <PrivateRoute>
              <ExpertRoute />
            </PrivateRoute>
          }
        >
          <Route path={`${ROUTES.RATINGS}/:id`} element={<Ratings />} />
          <Route path={`${ROUTES.EDITRATINGS}/:id`} element={<Ratings />} />
          <Route path={ROUTES.COMPLETERATINGS} element={<CompleteRatings />} />
          <Route
            path={ROUTES.AUDITORAPPLICATION}
            element={<AuditorApplication />}
          />
          <Route
            path={`${ROUTES.EDITAUDITORAPPLICATION}/:id`}
            element={<AuditorApplication />}
          />
        </Route>
        <Route path={ROUTES.SETTINGS} element={<Settings />}>
          <Route element={<PrivateRoute />}>
            <Route path={ROUTES.PROFILE} element={<Profile />} />
            <Route path={ROUTES.REVIEWS} element={<Reviews />} />

            <Route element={<ClientRoute />}>
              <Route path={ROUTES.MYINVESTORS} element={<MyInvestors />} />
              <Route path={ROUTES.MYINVESTMENT} element={<MyInvestment />} />
              <Route
                path={ROUTES.CREATEPROPOSAL}
                element={<CreateProposal />}
              />
              <Route
                path={ROUTES.VALIDATEPROJECT}
                element={<ValidateProject />}
              />
              <Route path={ROUTES.VOTING} element={<Voting />} />
              <Route
                path={`${ROUTES.WITHDRAWLHISTORY}/:id`}
                element={<WithdrawlHistory />}
              />
            </Route>
            <Route element={<ExpertRoute />}>
              <Route path={ROUTES.VOTE} element={<Vote />} />
            </Route>
          </Route>
        </Route>
        <Route path={ROUTES.ABOUT_US} element={<AboutUs />} />
        <Route path={ROUTES.HOW_IT_WORKS} element={<HowItWorks />} />
        <Route
          path={`${ROUTES.EXPERTPROFILE}/:id`}
          element={<ExpertProfile />}
        />
      </Routes>
      <LowerFooter />
    </Suspense>
  )
}

export default AppRoutes
