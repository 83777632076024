/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-useless-concat */
import React, { useEffect, useState } from 'react'
import { Country } from 'country-state-city'
import { Form } from 'antd'
import CountrySelectContainer from './CountrySelectContainer.styles'

function CountrySelect({
  labels,
  marginBottom,
  isRequired,
  handleCountrySelect,
  ...rest
}) {
  const [options, setOptions] = useState([])
  useEffect(() => {
    // setOptions(
    const value = Country.getAllCountries()?.map((item, i) => ({
      key: i,
      label: item?.name,
      value: item?.phonecode,
    }))
    setOptions(value)
  }, [labels])
  return (
    <Form.Item
      style={{ marginBottom }}
      rules={[
        {
          required: isRequired,
          message: 'Please input country code',
        },
      ]}
      name="countryCode"
    >
      <CountrySelectContainer
        showSearch
        filterOption={(input, option) =>
          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
        }
        placeholder="Country Code"
        onChange={handleCountrySelect}
        options={options}
        {...rest}
      />
    </Form.Item>
  )
}

export default CountrySelect
