import styled from 'styled-components'
import { deviceQuery } from '../../Constants/MediaSizes'

const StyledConnection = styled.div`
  .ant-dropdown-menu-title-content {
    /* identical to box height, or 150% */

    text-transform: capitalize;

    color: ${(props) => props.theme.colors.white};
  }
  .ant-dropdown > .ant-dropdown-menu {
    background: transparent;
    box-shadow: none;
    display: flex;
    flex-direction: column;
    gap: 10px;
    .ant-dropdown-menu-item {
      background: rgba(46, 114, 210, 0.3);
      border-radius: 40px;
      span {
        svg {
          fill: ${(props) => props.theme.colors.white};
        }
      }
      .ant-dropdown-menu-title-content {
        font-family: 'Gilroy';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
      }
    }

    .right-button {
      height: 60px !important;
      width: 200px !important;

      @media (${deviceQuery.desktopXL}) {
        height: 40px !important;
        width: 180px !important;
      }
    }
  }
`

export default StyledConnection
