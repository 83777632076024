import { createApi } from '@reduxjs/toolkit/query/react'
import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query'
import config from '../configuration/Config'
import { userApi } from './Users'

export const publicBaseQuery = fetchBaseQuery({
  baseUrl: config.BASE_URL,
  //   prepareHeaders: (headers, { getState }) => {
  //     const token = getState().commonRed.token;
  //     return headers;
  //   },
})

// Define a service using a base URL and expected endpoints
export const PublicApis = createApi({
  reducerPath: 'PublicApis',
  baseQuery: publicBaseQuery,
  endpoints: (builder) => ({
    getTransactions: builder.query({
      query: (data) => ({
        url: `/${data?.address}`,
        params: { page: data?.page, limit: '6' },
      }),
    }),
    login: builder.mutation({
      query: (data) => ({ url: '/auth/login', body: data, method: 'POST' }),
      onCacheEntryAdded: (args, { dispatch }) => {
        dispatch(userApi.util.invalidateTags(['user']))
      },
    }),
    signup: builder.mutation({
      query: (data) => ({ url: '/auth/register', body: data, method: 'POST' }),
    }),
    emailVerification: builder.mutation({
      query: (data) => ({
        url: '/auth/verify-email',
        body: data,
        method: 'PUT',
      }),
    }),
    getClientId: builder.query({
      query: () => '/auth/google/client-id',
    }),
    googleLogin: builder.mutation({
      query: (data) => ({ url: '/auth/google', body: data, method: 'POST' }),
      onCacheEntryAdded: (args, { dispatch }) => {
        dispatch(userApi.util.invalidateTags(['user']))
      },
    }),

    // resendEmail: builder.mutation({
    //   query: (data) => {
    //     return {
    //       url: `/auth/resend-email-verify-mail`,
    //       body: data,
    //       method: "PUT",
    //     };
    //   },
    // }),
    sendOTPEmail: builder.mutation({
      query: (data) => ({
        url: '/auth/send-email-verify-mail',
        body: data,
        method: 'POST',
      }),
    }),
    verifyEmail: builder.mutation({
      query: (data) => ({
        url: '/auth/verify-email',
        body: data,
        method: 'POST',
      }),
    }),
    sendOTPMobile: builder.mutation({
      query: (data) => ({
        url: '/auth/send-phone-number-verify-sms',
        body: data,
        method: 'POST',
      }),
    }),

    verifyMobile: builder.mutation({
      query: (data) => ({
        url: '/auth/verify-phone-number',
        body: data,
        method: 'POST',
      }),
    }),
    sendOtpForEmail: builder.mutation({
      query: (data) => ({
        url: 'auth/forgot-password/email',
        body: data,
        method: 'POST',
      }),
    }),
    sendOtpForPhone: builder.mutation({
      query: (data) => ({
        url: 'auth/forgot-password/phone-number',
        body: data,
        method: 'POST',
      }),
    }),
    verifyOtp: builder.mutation({
      query: (data) => ({
        url: 'auth/forgot-password/verify-otp',
        body: data,
        method: 'POST',
      }),
    }),
    resetPassword: builder.mutation({
      query: (data) => ({
        url: 'auth/reset-password',
        body: data,
        method: 'PUT',
      }),
    }),
    // logout: builder.mutation({
    //   query: (data) => {
    //     return { url: `/auth/logout`, body: data, method: "POST" };
    //   },
    // }),
    refreshToken: builder.mutation({
      query: (data) => ({ url: '/auth/refresh', body: data, method: 'POST' }),
    }),
    getProjectList: builder.query({
      query: () => '/projects',
    }),
    getProjectData: builder.query({
      query: (id) => `/projects/${id}`,

      transformResponse: (res) => ({
        ...res,
        project: {
          ...res?.project,
          distribution: res?.project?.distribution?.map((item) => ({
            y: item?.percentage,
            // y: (item?.percentage / totaDistribution) * 100,
            name: item?.title,
          })),
          // fundingAllocation: res?.project?.fundingAllocation?.map((item) => ({
          //   y: item?.percentage,
          //   name: item?.title,
          // })),
          keyData: res?.project?.keyData?.map((item) => ({
            y: item?.percentage,
            name: item?.title,
          })),
        },
      }),
    }),
    getAllAuditorsRating: builder.query({
      query: (data) => ({
        url: `/projects/rating/auditors/${data?.id}`,
        params: { page: data?.page, limit: '6' },
      }),
      providesTags: ['auditor'],
    }),
    getAuditorsRating: builder.query({
      query: (id) => `/projects/rating/${id}`,
    }),
    getProjectRatings: builder.query({
      query: (data) => ({
        url: `/projects/rating/all/${data?.id}`,
        params: { page: data?.page, limit: '4' },
      }),
    }),
    getExpertProfile: builder.query({
      query: (id) => `/users/auditor/${id}`,
    }),
    getTotalInvestments: builder.query({
      query: () => '/investment/total-investments',
    }),
    zynPurchase: builder.mutation({
      query: (data) => ({
        url: '/users/zyn-purchase',
        body: data,
        method: 'POST',
      }),
    }),
  }),
})

export const {
  useLazyGetTransactionsQuery,
  useLazyGetTotalInvestmentsQuery,
  useLoginMutation,
  useSignupMutation,
  useEmailVerificationMutation,
  useGetClientIdQuery,
  useGoogleLoginMutation,
  useSendOTPEmailMutation,
  useSendOTPMobileMutation,
  useVerifyEmailMutation,
  useVerifyMobileMutation,
  // useResendEmailMutation,
  useSendOtpForEmailMutation,
  useSendOtpForPhoneMutation,
  useVerifyOtpMutation,
  useResetPasswordMutation,
  // useLogoutMutation,
  useRefreshTokenMutation,
  useGetProjectListQuery,
  useGetProjectDataQuery,
  useLazyGetAllAuditorsRatingQuery,
  useGetAuditorsRatingQuery,
  useLazyGetProjectRatingsQuery,
  useGetExpertProfileQuery,
  useZynPurchaseMutation,
} = PublicApis
