import styled from 'styled-components'

const StyledCompleteRatings = styled.div`
  .nav {
    padding: 20px;
    background: url('/assets/SideView.png') no-repeat center;
    background-size: cover;
    margin-bottom: 60px;
  }
  .login-head {
    display: flex;
    flex-direction: column;
    padding: 60px 0;
    gap: 10px;
  }
  .main-container {
    width: 50%;
    margin: auto;
    background: rgba(44, 40, 96, 255);
    border-radius: 20px;
    display: flex;
    flex-direction: column;

    .img {
      width: 100%;
      img {
        width: 70%;
      }
    }
    h2 {
      font-family: 'Gilroy';
      font-style: normal;
      font-weight: 900;
      font-size: 26px;
      line-height: 52px;
      /* or 93% */

      text-align: center;

      color: ${(props) => props.theme.colors.blue.light};
    }
    p {
      font-family: 'Gilroy';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 32px;
      /* or 93% */

      text-align: center;

      color: ${(props) => props.theme.colors.white};
    }
    .text {
      width: 80%;
      margin: auto;
    }
  }
  .btns {
    padding-top: 40px;
    display: flex;
    gap: 10px;
    justify-content: center;

    Button {
      height: 40px;
      border: 1px solid ${(props) => props.theme.colors.blue.light};
      background: transparent;
      border-radius: 18px;
      padding: 0px 40px;

      text-align: center;
      color: ${(props) => props.theme.colors.white};
      span {
        font-family: Urbanist;
        font-size: 14px;
        font-weight: 600;
      }
    }
    button:not(:first-child) {
      color: ${(props) => props.theme.colors.blue.dark};
      background: ${(props) => props.theme.colors.blue.light};
    }
  }
`
export default StyledCompleteRatings
