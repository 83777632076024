import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'antd'
import { StyledNavlink } from '../../GlobalStyles'
import ROUTES from '../../Constants/Routes'
import StyledDrawer from './StyledDrawer.styles'
import DisconnectButton from '../DisconnectButton'
import ConnectionButton from '../ConnectionButton'
import { setAddress, setProvider } from '../../Services/Auth'
import { removeLocalStorageItem } from '../../Utils/localStorage'
import useContextApp from '../../Hooks/useContextApp'

function CustomDrawer({ onClose, open, userData, LogOut, setWalletOpen }) {
  const { isLogin } = useSelector((state) => state.commonRed)
  const { walletAddress, setWalletAddress, setWebProvider } = useContextApp()
  const dispatch = useDispatch()
  const disconnect = useCallback(() => {
    removeLocalStorageItem('userwalletAddress')
    removeLocalStorageItem('signature')
    dispatch(setAddress(''))
    dispatch(setProvider({}))
    setWalletAddress('')
    setWebProvider({})
  }, [dispatch, setWalletAddress, setWebProvider])
  return (
    <StyledDrawer
      getContainer={(trigger) => trigger}
      title=""
      placement="right"
      onClose={onClose}
      open={open}
    >
      <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
        <StyledNavlink
          style={{ color: '#ffffff' }}
          to={ROUTES.HOW_IT_WORKS}
          onClick={onClose}
        >
          How It Works
        </StyledNavlink>
        <StyledNavlink
          style={{ color: '#ffffff' }}
          to={ROUTES.PROJECTS}
          onClick={onClose}
        >
          Submitted Ideas
        </StyledNavlink>
        <StyledNavlink
          style={{ color: '#ffffff' }}
          to={ROUTES.ABOUT_US}
          onClick={onClose}
        >
          About Us
        </StyledNavlink>
        {isLogin ? (
          <>
            <Button
              className="button"
              ghost
              shape="round"
              onClick={() => {
                setWalletOpen(true)
                onClose()
              }}
            >
              <span>{walletAddress || 'Connect Wallet'}</span>
            </Button>

            <DisconnectButton
              disconnect={disconnect}
              onClose={onClose}
              userData={userData}
              LogOut={LogOut}
            />
          </>
        ) : (
          <ConnectionButton drawer onClose={onClose} />
        )}
      </div>
    </StyledDrawer>
  )
}

export default CustomDrawer
