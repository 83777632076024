import { createSlice } from '@reduxjs/toolkit'

export const globalSlice = createSlice({
  name: 'global',
  initialState: {
    user: {},
    token: '',
    isLogin: false,
    address: '',
    provider: {},
  },
  reducers: {
    setToken: (state, action) => ({
      ...state,
      token: action.payload,
    }),
    setUser: (state, action) => ({
      ...state,
      user: action.payload.user,
    }),
    setIsLogin: (state, action) => ({
      ...state,
      isLogin: action.payload,
    }),
    setAddress: (state, action) => ({
      ...state,
      address: action.payload,
    }),
    setProvider: (state, action) => ({
      ...state,
      provider: action.payload,
    }),
  },
})

// Action creators are generated for each case reducer function
export const { setToken, setUser, setIsLogin, setAddress, setProvider } =
  globalSlice.actions
export default globalSlice.reducer

// Define a service using a base URL and expected endpoints
